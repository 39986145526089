import { memo } from 'react';

import { Grid, Stack, Skeleton, Card } from '@mui/joy';

const DonnasNotesLoader = memo(() => {
  return (
    <Stack
      gap={3}
      alignContent={'flex-start'}
      sx={{ pb: 12, maxWidth: '90vw', minWidth: 'lg', mx: 'auto', pt: 3 }}
    >
      <Skeleton variant='rectangular' width={'200px'} height={'40px'} />
      <Stack
        gap={3}
        justifyContent={'flex-start'}
        direction={'row'}
        alignItems={'center'}
        sx={{ height: '100%' }}
        width={'100%'}
      >
        <Stack gap={1} width={'100%'}>
          <Skeleton variant='rectangular' height={'20px'} />
          <Skeleton variant='rectangular' height={'20px'} />
        </Stack>
      </Stack>
      <Card
        sx={{
          padding: 4,
          height: '100%',
          borderRadius: 'sm',
        }}
        variant='outlined'
      >
        <Grid xs={12} gap={1} direction={'column'} display={'flex'}>
          <Stack gap={1} direction={'column'} width={'100%'}>
            <Skeleton variant='rectangular' width={'200px'} height={'32px'} />
            <Stack>
              <Skeleton variant='rectangular' height={'28px'} />
            </Stack>
            <Stack width={'100%'} gap={3} pb={1}>
              <Stack gap={1}>
                <Skeleton variant='rectangular' height={'20px'} />
                <Skeleton variant='rectangular' height={'20px'} />
                <Skeleton variant='rectangular' height={'20px'} />
              </Stack>
            </Stack>
            <Stack width={'100%'} gap={3} pb={1}>
              <Stack gap={1}>
                <Skeleton variant='rectangular' height={'20px'} />
                <Skeleton variant='rectangular' height={'20px'} />
                <Skeleton variant='rectangular' height={'20px'} />
              </Stack>
            </Stack>
            <Stack width={'100%'} gap={3} pb={1}>
              <Stack gap={1}>
                <Skeleton variant='rectangular' height={'20px'} />
                <Skeleton variant='rectangular' height={'20px'} />
                <Skeleton variant='rectangular' height={'20px'} />
              </Stack>
            </Stack>
            <Stack width={'100%'} gap={3} pb={1}>
              <Stack gap={1}>
                <Skeleton variant='rectangular' height={'20px'} />
                <Skeleton variant='rectangular' height={'20px'} />
                <Skeleton variant='rectangular' height={'20px'} />
              </Stack>
            </Stack>
            <Stack width={'100%'} gap={3} pb={1}>
              <Stack gap={1}>
                <Skeleton variant='rectangular' height={'20px'} />
                <Skeleton variant='rectangular' height={'20px'} />
                <Skeleton variant='rectangular' height={'20px'} />
              </Stack>
            </Stack>
            <Stack width={'100%'} gap={3} pb={1}>
              <Stack gap={1}>
                <Skeleton variant='rectangular' height={'20px'} />
                <Skeleton variant='rectangular' height={'20px'} />
                <Skeleton variant='rectangular' height={'20px'} />
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Card>
    </Stack>
  );
});

export default DonnasNotesLoader;
