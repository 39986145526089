import { Chip, ChipDelete } from '@mui/joy';
import { RxCross1 } from 'react-icons/rx';

import { getGHGGradeFromRatingNumber } from '../constants';

interface PropTypes {
  onClickHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  value: string;
  filter: string;
}

const chipValueRenderer = (filter: string, value: string) => {
  switch (filter) {
    case 'ghgRatingNumber':
      return `GHG ${getGHGGradeFromRatingNumber(Number(value))}`;
    case 'sdgRatingNumber':
      return value === '0' ? 'SDG No Cert' : `SDG +${value}`;
    case 'confirmedSDGs':
      return `SDGs ${value}`;
    case 'isFollowedByCurrentUser':
      return 'Following';
    default:
      return value;
  }
};

export const AppliedFilterChip = ({ onClickHandler, filter, value }: PropTypes) => {
  return (
    <Chip
      variant='outlined'
      color='primary'
      endDecorator={
        <ChipDelete onClick={onClickHandler}>
          <RxCross1 />
        </ChipDelete>
      }
      sx={{ paddingX: 1.5, paddingY: '4px' }}
      size='md'
    >
      {chipValueRenderer(filter, value)}
    </Chip>
  );
};
