import { styled } from '@mui/joy';

import { ViewTypes } from '../../pages/search/constants';
import { CHART_VIEW } from '../../pages/search/Search';

export const AGGridUIWrapper = styled('div')<{
  isDarkModeOn: boolean;
  selectedView?: ViewTypes;
  isOnSearchPage?: boolean;
}>(({ isDarkModeOn, selectedView, theme, isOnSearchPage = false }) => ({
  position: 'relative',
  '.ag-theme-alpine .new-box ': {
    backgroundColor: `rgba(20, 18, 16, 0.168627451) !important`,
  },
  '.ag-header ': {
    background: isDarkModeOn ? '#161A1C !important' : '#FBFCFE !important',
    borderBottom: `1px solid ${isDarkModeOn ? '#424242' : '#E5E5E5'} !important`,
    fontSize: isOnSearchPage ? '16px' : null,
  },
  '.ag-row:nth-child(even)': {
    background: isOnSearchPage
      ? isDarkModeOn
        ? '#0B0D0E !important'
        : '#FFF !important'
      : isDarkModeOn
        ? '#161A1C !important'
        : '#FBFCFE !important',
  },
  '.ag-row:nth-child(odd)': {
    background: isDarkModeOn ? '#0B0D0E !important' : null,
  },
  '.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell)': {
    borderRight: `1px solid ${isDarkModeOn ? '#424242' : '#E5E5E5'} !important`,
  },
  '.ag-pinned-left-header': {
    borderRight: `1px solid ${isDarkModeOn ? '#424242' : '#E5E5E5'} !important`,
  },
  '.ag-paging-panel ': {
    background: isDarkModeOn ? '#161A1C !important' : '#FBFCFE !important',
    borderTop: `1px solid ${isDarkModeOn ? '#424242' : '#E5E5E5'} !important`,
  },
  '.ag-side-buttons': {
    display: 'none',
  },
  '.ag-body': {
    background: isDarkModeOn ? '#161A1C !important' : '#FBFCFE !important',
  },
  '.ag-root-wrapper':
    selectedView?.value === CHART_VIEW
      ? {
          visibility: 'hidden',
        }
      : {
          border: `1px solid ${isDarkModeOn ? '#424242' : '#E5E5E5'} !important`,
          borderRadius: '8px',
        },
  '.ag-filter-toolpanel':
    selectedView?.value === CHART_VIEW
      ? {
          visibility: 'visible',
          overflow: 'scroll',
          zIndex: 1,
          border: `1px solid ${theme.palette.neutral.outlinedBorder}`,
          background: theme.palette.background.surface,
          position: 'relative',
        }
      : {},
  '.ag-header-cell-resize': {
    display: 'none',
  },
  '.volume-by-retirement-year-header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.ag-side-bar': {
    borderLeft: 'none !important',
  },
  '.ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total)':
    {
      borderTop: `1px solid ${isDarkModeOn ? '#424242' : '#E5E5E5'} !important`,
    },
  '.ag-pinned-right-header': {
    borderLeft: `1px solid ${isDarkModeOn ? '#424242' : '#E5E5E5'} !important`,
  },
  '.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell)': {
    borderLeft: `1px solid ${isDarkModeOn ? '#424242' : '#E5E5E5'} !important`,
  },
}));
