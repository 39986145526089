import { useMemo } from 'react';

import { Box, Card, Chip, Divider, Grid, Skeleton, Stack, Tooltip, Typography } from '@mui/joy';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { useRecoilValue } from 'recoil';

import LocationPinIcon from 'jsx:../../../../assets/icons/location-pin.svg';

import Image from '../../../../components/Image';
import { StyledHTML } from '../../../../components/styled/StyledHTML';
import { assetBaseUrl, getRatingUrl } from '../../../../services/axios/endpoints';
import { projectState } from '../../../../stores/project';
import { arrayToEnglishString } from '../../../../utils/functions/array';
import { sdgImapctNegative, sdgImpactPositive } from '../../constants';
import { NormaliseCSSWrapper } from '../../ghg_rating/components/HTMLRenderer';

export const ProjectInfo = () => {
  const { projectData, isLoading } = useRecoilValue(projectState);
  const states = useMemo(
    () => arrayToEnglishString(projectData?.location?.states || []),
    [projectData?.location.states]
  );

  if (isLoading)
    return (
      <Card sx={{ position: 'relative', minHeight: '400px', p: 2 }}>
        <Skeleton width={'95%'} height={'90%'} />
      </Card>
    );

  return (
    <Card sx={{ p: (theme) => theme.spacing(3) }}>
      <Typography level='h2'>{projectData?.title}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Stack direction={'row'} alignItems={'center'} gap={1}>
          <Typography level='body-md'>{projectData?.code}</Typography>
        </Stack>
        <Divider orientation='vertical' sx={{ height: '25px' }} />
        <Stack direction={'row'} alignItems={'center'} gap={0.5}>
          <Typography level='body-md' component={'span'}>
            <LocationPinIcon />
          </Typography>
          <Typography level='body-md' fontWeight={'xl'}>
            {states}
            {!!projectData?.location.state ? ',' : ''} {projectData?.location.country}
          </Typography>
          <Stack
            sx={{ width: '18px', ml: 1 }}
            direction={'row'}
            alignContent={'center'}
            justifyContent={'center'}
          >
            <Image
              src={`${assetBaseUrl}/${projectData?.location?.flag || ''}`}
              style={{ width: '100%', height: '100%' }}
            />
          </Stack>
        </Stack>
      </Box>
      <NormaliseCSSWrapper>
        <StyledHTML
          dangerouslySetInnerHTML={{
            __html: projectData?.description || '',
          }}
        />
      </NormaliseCSSWrapper>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography level='h4'>Calyx Global Confirmed SDGs</Typography>
        <Tooltip title='Calyx Global identified relevant and sufficient evidence indicating that the project contributes to the following SDGs.'>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ml: 1 }}>
            <IoInformationCircleOutline />
          </Box>
        </Tooltip>
      </Box>
      {projectData?.sdgImpactRating <= 0 ? (
        <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
          {projectData?.sdgImpactRating === -1 ? sdgImapctNegative : sdgImpactPositive}
        </Typography>
      ) : (
        <Stack flexWrap={'wrap'} direction={'row'} gap={2} sx={{ mb: 2 }}>
          {(projectData?.confirmedSdgs || []).map((sdg) => {
            const url = getRatingUrl(`E-WEB-Goal-SDG_${sdg}.svg`);
            return (
              <Box sx={{ width: '64px', height: '64px', borderRadius: 4, overflow: 'hidden' }}>
                <Image
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  src={url}
                  alt=''
                />
              </Box>
            );
          })}
        </Stack>
      )}
      <Divider sx={{ width: '100%', mx: 'auto', my: (theme) => theme.spacing(2) }} />
      <Grid container columnSpacing={2}>
        <Grid xs={4} sx={{ mb: 2 }}>
          <Stack gap={1}>
            <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
              Project type
            </Typography>
            <Typography level='body-md' color='primary' sx={{ pr: 3 }}>
              {projectData?.projectType}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={4} sx={{ mb: 2 }}>
          <Stack gap={1}>
            <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
              Methodology
            </Typography>
            <Typography level='body-md' color='primary' sx={{ pr: 3 }}>
              {projectData?.methodology}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={4} sx={{ mb: 2 }}>
          <Stack gap={1}>
            <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
              Certificates
            </Typography>
            <Typography level='body-md' color='primary' sx={{ pr: 3 }}>
              <Chip variant='outlined'>{projectData?.certificate || '-'}</Chip>
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={4}>
          <Stack gap={1}>
            <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
              Project duration
            </Typography>
            <Typography level='body-md' color='primary' sx={{ pr: 3 }}>
              {projectData?.duration}
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={4}>
          <Stack gap={1}>
            <Typography level='body-sm' sx={{ color: (theme) => theme.palette.text.secondary }}>
              Proponent and Partners
            </Typography>
            <Typography level='body-md' color='primary' sx={{ pr: 3 }}>
              {projectData?.proponents}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};
