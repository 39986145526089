import { atom } from 'recoil';

import { group, identify } from '../../services/analytics';
import { USER_INFO } from '../../utils/constants/localStorage';
import { getLocalStorageObject, setLocalStorageObject } from '../../utils/functions/localStorage';

export type UserStateTypes = {
  company: string;
  country: string;
  mfa: boolean;
  name: string;
  profile_image: string;
  surname: string;
  user_id: string;
  email: string;
  calyx_team: boolean;
  project_access_type: string;
  user_permissions: string[];
  user_roles: string[];
} | null;
export const USER_STATE_KEY = 'user_state';

export const userState = atom<UserStateTypes>({
  key: USER_STATE_KEY,
  default: getLocalStorageObject(USER_INFO),
  effects: [
    ({ onSet }) => {
      onSet((userState) => {
        setLocalStorageObject(USER_INFO, userState);
        identify(`${userState?.email}`, userState);
        group('company', {
          name: userState?.company,
        });
      });
    },
  ],
});

export const REDIRECTION_URL_STATE_KEY = 'redirection_url_state_key';
export const redirectionURLState = atom<string>({
  key: REDIRECTION_URL_STATE_KEY,
  default: '/',
});
