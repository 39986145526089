import { useState } from 'react';

import { Box, Modal, ModalClose, ModalDialog, Stack, Typography } from '@mui/joy';
import { FaLock } from 'react-icons/fa';
import { IoMdLock } from 'react-icons/io';
import { TbBulb } from 'react-icons/tb';
import { useRecoilValue } from 'recoil';

import { StyledLink } from '../../../../../components/styled/StyledLink';
import { projectState } from '../../../../../stores/project';
import { useFetchUserPermissions } from '../../../../../utils/hooks/useFetchUserPermission';
import { Chart } from '../Chart';
import { HTML } from '../HTML';
import ImageContainer from '../Image';
import { Map } from '../Map';
import { Paragraph } from '../Paragraph';
import { Table } from '../Table';
import { TimelineComponent } from '../TimeLine';
import { Title } from '../Title';

import { ReadMoreFallback } from './ReadMoreFallback';

interface PropTypes {
  selectedTab: any;
}

export const ReadMoreSection = ({ selectedTab }: PropTypes) => {
  const [showSubscriptionNotice, setShowSubscriptionNotice] = useState<boolean>(false);
  const { projectData, projectCreditingData } = useRecoilValue(projectState);
  const { hasPermissionForReadMore } = useFetchUserPermissions();

  const handleNotAllowedReadMore = () => {
    setShowSubscriptionNotice((prev) => !prev);
  };

  return (
    <>
      <Modal open={showSubscriptionNotice} onClose={handleNotAllowedReadMore}>
        <ModalDialog maxWidth={'500px'}>
          <ModalClose />
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'column'}
            gap={2}
          >
            <Stack alignItems={'center'} direction={'row'} spacing={1}>
              <FaLock size={24} />
              <Typography level='h3'>Read More</Typography>
            </Stack>
            <Typography fontSize={'md'} textAlign={'center'} fontWeight={'xl'}>
              This feature requires an Expert/Enterprise-level subscription. Please email us at{' '}
              <StyledLink to='mailto:customersuccess@calyxglobal.com'>
                info@calyxglobal.com
              </StyledLink>{' '}
              to explore access.
            </Typography>
          </Box>
        </ModalDialog>
      </Modal>
      <Box
        height={'56px'}
        width={'lg'}
        sx={{
          background: (theme) => theme.palette['linearGradient']['special'],
        }}
        display={'flex'}
        alignItems={'center'}
        paddingX={3}
      >
        <Stack
          direction={'row'}
          gap={1}
          alignItems={'center'}
          sx={(theme) => {
            return { color: theme.palette.common.white };
          }}
        >
          {hasPermissionForReadMore ? <TbBulb size={20} /> : <IoMdLock size={20} />}
          <Typography
            fontSize={'lg'}
            fontWeight={'md'}
            sx={(theme) => {
              return { color: theme.palette.common.white };
            }}
          >
            Deep-Dive Analysis
          </Typography>
        </Stack>
      </Box>
      {hasPermissionForReadMore ? (
        <Box overflow={'hidden'} pb={2}>
          <Stack spacing={3} padding={3}>
            {selectedTab?.readMoreContent?.map((item: any, index: number) => {
              const type = item?.type;
              if (type === 'title') {
                return (
                  <Title
                    key={`${index}-${type}`}
                    titleType={item?.titleType}
                    textContent={item?.content}
                  />
                );
              }
              if (type === 'paragraph') {
                return (
                  <Paragraph
                    key={`${index}-${type}`}
                    textContent={item?.content}
                    selectedTab={selectedTab?.type}
                    titleText={item?.title}
                  />
                );
              }
              if (type === 'table') {
                return (
                  <Table
                    key={`${index}-${type}`}
                    content={item?.content}
                    tableType={item?.tableType}
                    caption={item?.caption}
                  />
                );
              }
              if (type === 'image') {
                return (
                  <ImageContainer
                    key={`${index}-${type}`}
                    alt={item?.imageAltText}
                    description={item?.description}
                    src={item?.imageUrl}
                  />
                );
              }
              if (type === 'timeline_table') {
                return (
                  <TimelineComponent
                    key={`${index}-${type}`}
                    timelineArray={item?.timelineArray}
                    timelineDescription={item?.timelineDescription}
                  />
                );
              }
              if (type === 'map') {
                return (
                  <Map
                    key={`${index}-${type}`}
                    description={item?.description}
                    maptilerLayers={item?.maptilerLayers}
                    maptilerLink={item?.maptilerLink}
                    maptilerViewState={item?.maptilerViewState}
                  />
                );
              }
              if (type === 'chart') {
                return (
                  <Chart
                    key={`${index}-${type}`}
                    chartConfigProp={item?.chartConfig}
                    chartData={item?.chartData}
                    isHidden={item?.isHidden}
                    chartType={item?.chartType}
                    description={item?.description}
                    title={item?.title}
                    uniqueIdentifier={item?.uniqueIdentifier}
                  />
                );
              }
              if (type === 'html') {
                return (
                  <HTML
                    key={`${index}-${type}`}
                    title={item?.title}
                    htmlContent={item?.htmlContent}
                  />
                );
              }
            })}
          </Stack>
        </Box>
      ) : (
        <ReadMoreFallback
          project={projectData}
          tab='GHG'
          projectCreditingData={projectCreditingData}
          selectedTab={selectedTab}
        />
      )}
    </>
  );
};
