import { useState } from 'react';

import { Button, Card, Stack, Textarea, Typography } from '@mui/joy';
import { useSetRecoilState } from 'recoil';

import { makeAuthenticatedPostRequest } from '../../services/axios';
import { contact } from '../../services/axios/endpoints';
import { snackbarState } from '../../stores/snackerbar';

const Contact = () => {
  const setSnackbar = useSetRecoilState(snackbarState);

  const [message, setMessage] = useState<string>('');
  const [isMessageSent, setIsMessageSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSendMessage = async () => {
    setIsLoading(true);
    try {
      await makeAuthenticatedPostRequest(contact, {
        message,
      });
      setIsMessageSent(true);
    } catch (_err) {
      setSnackbar({ message: 'Something went wrong. Please try again.', color: 'danger' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack
      direction={'column'}
      gap={4}
      maxWidth={'1000px'}
      margin='auto'
      marginTop={6}
      alignItems={'center'}
    >
      {isMessageSent ? (
        <Card sx={{ minWidth: '600px', margin: 'auto' }}>
          <Typography
            level='h1'
            textAlign={'center'}
            sx={(theme) => {
              return { color: theme.palette.text.primary };
            }}
          >
            Thank you, we will get back to you soon.
          </Typography>
        </Card>
      ) : (
        <>
          <Card sx={{ minWidth: '600px' }}>
            <Stack spacing={3}>
              <Typography
                color='primary'
                level='h2'
                sx={(theme) => {
                  return { color: theme.palette.text.primary };
                }}
              >
                Contact Us
              </Typography>
              <Stack spacing={1}>
                <Typography fontWeight={'xl'}>Message</Typography>
                <Textarea
                  minRows={6}
                  maxRows={12}
                  placeholder='How can we help you?'
                  onChange={(e) => setMessage(e.target.value)}
                />
                <br />
                <Button
                  fullWidth
                  size='lg'
                  sx={(theme) => {
                    return { fontWeight: theme.fontWeight.lg };
                  }}
                  onClick={handleSendMessage}
                  loading={isLoading}
                >
                  Send
                </Button>
              </Stack>
            </Stack>
          </Card>
          {process.env.IS_PRODUCTION_APP === 'true' ? (
            <Typography level='body-sm' sx={{ py: 5 }} textAlign={'center'}>
              App Version {process.env.GITHUB_VERSION_NUMBER}
            </Typography>
          ) : null}
        </>
      )}
    </Stack>
  );
};

export { Contact };
