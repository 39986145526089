import React, { useState } from 'react';

import { Box, Card, Typography } from '@mui/joy';

import InherentRisksSections from './components/InherentRisksSections';
import ProjectSpecificRisksSection from './components/ProjectSpecificRisksSection';
import RequestEsrReport from './components/RequestEsrReport';
import SocialSafeguardsSection from './components/SocialSafeguardsSection';
import { screeningList } from './constants';

export const Safeguards: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <Box sx={{ pb: 12, maxWidth: '90vw', minWidth: 'lg', mx: 'auto', pt: 3 }}>
        <Typography level='h1' fontSize={'xl3'}>
          Environmental and Social Safeguards
        </Typography>
        <Typography level='body-md' sx={{ mt: 2 }}>
          Carbon projects are intended to have a positive impact on the climate and hold an immense
          potential to benefit local communities and ecosystems, as well as promote sustainable
          development. At their core, projects must follow a do-no-harm approach, avoiding negative
          social, environmental, or economic impacts. However, experience around the globe shows
          that these projects can affect the most vulnerable stakeholders, such as indigenous
          peoples, local communities, women, and the most critical ecosystems, if environmental and
          social safeguards are not effectively adopted.
        </Typography>
        <Typography level='body-md' sx={{ mt: 2 }}>
          “Safeguards” is a term used by investors, as well as in the project development world, to
          encompass policies, standards and operational procedures designed to avoid the
          exploitation of local peoples, resource insecurities, human rights violations, and damage
          to local ecosystems, among other things. For carbon projects, environmental and social
          safeguards aim to curb inadvertent, negative outcomes of a project. When in place,
          safeguards also mitigate reputational risks associated with investing in projects that
          cause harm.
        </Typography>
        <Card sx={{ mt: 3 }}>
          <SocialSafeguardsSection
            title='Protecting from Environmental and Social Risk'
            text={[
              'The existing safeguards requirements of today’s carbon standards fall short and do not guarantee that a project has taken sufficient measures to ensure no harm. To assist you in managing environmental, social and reputational risks, Calyx Global has developed an Environmental and Social Risks (ESR) approach that identifies and provides you with a clear understanding of carbon project risks. Today, we offer inherent risks by project type and a deeper-dive analysis through project-specific risk reports.',
            ]}
            areasTitle='Areas of ESR screening and analysis align with widely established industry best practices.'
            areas={screeningList}
          />
        </Card>
        <InherentRisksSections />
        <ProjectSpecificRisksSection setIsModalOpen={setIsModalOpen} />
      </Box>
      <RequestEsrReport setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
    </>
  );
};
