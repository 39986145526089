import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.min.css';
import { useRef } from 'react';

import { styled, useColorScheme } from '@mui/joy';
import { AgGridReact } from 'ag-grid-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { AGGridUIWrapper } from '../../../../../components/styled/AGGridUIWrapper';
import { track } from '../../../../../services/analytics';
import {
  EVENT_PROJECT_COMPARISON_PAGE,
  EVENT_RETIREMENT_INSIGHTS_PAGE,
  EVNTAPP_retiree_analytics_change_pageSize,
  EVNTAPP_retiree_analytics_sort_2020,
  EVNTAPP_retiree_analytics_sort_2021,
  EVNTAPP_retiree_analytics_sort_2022,
  EVNTAPP_retiree_analytics_sort_2023,
  EVNTAPP_retiree_analytics_sort_2024,
  EVNTAPP_retiree_analytics_sort_retiree,
  EVNTAPP_retiree_analytics_sort_total,
  EVNTAPP_retiree_insights_change_pageSize,
  EVNTAPP_retiree_insights_sort_2020,
  EVNTAPP_retiree_insights_sort_2021,
  EVNTAPP_retiree_insights_sort_2022,
  EVNTAPP_retiree_insights_sort_2023,
  EVNTAPP_retiree_insights_sort_2024,
  EVNTAPP_retiree_insights_sort_retiree,
  EVNTAPP_retiree_insights_sort_total,
} from '../../../../../services/analytics/events';
import { projectState } from '../../../../../stores/project';

import { retireeInsightsAGGridColumnsDefs } from './constants';

const GridWrapper = styled('div')({
  '.disabled-row:hover,.disabled-row::before': {
    backgroundColor: 'transparent !important',
    cursor: 'default !important',
  },
});

interface PropTypes {
  data: Array<any>;
  searchText?: string;
  isLoading: boolean;
  isWidget?: boolean;
}

export const RetireeInsightsTable = (props: PropTypes) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectData } = useRecoilValue(projectState);
  const { data, searchText, isLoading = false, isWidget = false } = props;
  const agGridRef = useRef<AgGridReact>(null);
  const { mode } = useColorScheme();

  const handlePaginationChanged = (e) => {
    if (e.api.paginationGetCurrentPage() !== 0 || e.api.paginationGetPageSize() !== 10) {
      track(
        isWidget
          ? EVNTAPP_retiree_analytics_change_pageSize
          : EVNTAPP_retiree_insights_change_pageSize,
        isWidget ? EVENT_PROJECT_COMPARISON_PAGE : EVENT_RETIREMENT_INSIGHTS_PAGE,
        isWidget
          ? {
              project_id: projectData?.projectId,
              page_url: `${location.pathname}${location.search}`,
            }
          : {}
      );
    }
  };

  const handleSortChanged = (e) => {
    const sortedColumn = (e.columns?.[e.columns?.length - 1] as any)?.colDef?.headerName;
    let event = '';
    switch (sortedColumn) {
      case 'Retiree':
        event = isWidget
          ? EVNTAPP_retiree_analytics_sort_retiree
          : EVNTAPP_retiree_insights_sort_retiree;
        break;

      case '2024 YTD':
        event = isWidget ? EVNTAPP_retiree_analytics_sort_2024 : EVNTAPP_retiree_insights_sort_2024;
        break;

      case '2023':
        event = isWidget ? EVNTAPP_retiree_analytics_sort_2023 : EVNTAPP_retiree_insights_sort_2023;
        break;

      case '2022':
        event = isWidget ? EVNTAPP_retiree_analytics_sort_2022 : EVNTAPP_retiree_insights_sort_2022;
        break;

      case '2021':
        event = isWidget ? EVNTAPP_retiree_analytics_sort_2021 : EVNTAPP_retiree_insights_sort_2021;
        break;

      case '≤2020':
        event = isWidget ? EVNTAPP_retiree_analytics_sort_2020 : EVNTAPP_retiree_insights_sort_2020;
        break;
      case 'Total(tCO2e)':
        event = isWidget
          ? EVNTAPP_retiree_analytics_sort_total
          : EVNTAPP_retiree_insights_sort_total;
        break;
    }
    track(
      event,
      isWidget ? EVENT_PROJECT_COMPARISON_PAGE : EVENT_RETIREMENT_INSIGHTS_PAGE,
      isWidget
        ? {
            project_id: projectData?.projectId,
            page_url: `${location.pathname}${location.search}`,
          }
        : {}
    );
  };

  const isDisabledRow = (row) => {
    if (!row) return false;
    const retiree = row?.retiree || '';
    if (retiree.toLowerCase() === 'total unknown companies') {
      return true;
    }
    return false;
  };

  const handleOnRowClicked = (row: any) => {
    if (isDisabledRow(row?.data)) return;
    navigate(`/retirement_insights/${row?.data?.retiree}`);
  };

  return (
    <GridWrapper>
      <AGGridUIWrapper
        style={{ height: 'calc(100vh - 350px)', width: '100%' }}
        className={mode === 'dark' ? 'ag-theme-alpine-dark ' : 'ag-theme-alpine'}
        isDarkModeOn={mode === 'dark'}
      >
        <AgGridReact
          ref={agGridRef}
          rowData={isLoading ? null : data ?? []}
          columnDefs={retireeInsightsAGGridColumnsDefs}
          pagination
          paginationPageSize={10}
          paginationPageSizeSelector={[10, 20, 30, 50, 500, 1000]}
          quickFilterText={searchText}
          onPaginationChanged={handlePaginationChanged}
          onSortChanged={handleSortChanged}
          suppressContextMenu
          suppressCellFocus
          defaultColDef={{
            flex: 1,
            minWidth: 150,
            resizable: true,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            cellStyle: { whiteSpace: 'normal', cursor: 'pointer' },
          }}
          rowClassRules={{
            'disabled-row': (params) => isDisabledRow(params?.data),
          }}
          getRowStyle={(params) => {
            if (isDisabledRow(params?.data)) {
              return {
                cursor: 'default',
                opacity: 0.5,
              };
            }
          }}
          onRowClicked={handleOnRowClicked}
        />
      </AGGridUIWrapper>
    </GridWrapper>
  );
};
