import { Box, Chip } from '@mui/joy';
import { uniq } from 'lodash';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';

import { theme } from '../../../theme';
import { dark } from '../../../theme/dark';
import { light } from '../../../theme/light';
export const ghgSummaryTransformer = (data: any) => {
  if (!data?.detailed_data_available) {
    return {
      isDetailedDataAvailable: false,
      description: data?.ghg_key_blurbs,
    };
  }
  const getFormatedTitle = (title) => {
    const text = title.toLowerCase();
    switch (text) {
      case 'overcrediting':
        return 'Over-crediting';
      case 'overlapping claims':
        return 'Overlapping Claims';
      default:
        return title;
    }
  };
  const ghgSummaryParagraph = data?.project_crediting_periods?.[0]?.rating_blurbs
    ?.find(({ blurb_type }) => blurb_type === 'ghg')
    ?.rating_blurb_sections?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim();
  const ratingSections = data?.project_crediting_periods?.[0]?.rating_blurbs
    ?.find(({ blurb_type }) => blurb_type === 'ghg')
    ?.children?.sort((a: any, b: any) => {
      return a?.order - b?.order || a.id - b.id;
    })
    ?.map((section: any) => {
      return {
        type: section?.blurb_type,
        title: getFormatedTitle(section?.title),
        content: section?.rating_blurb_sections?.map((blurb: any) => {
          const type = blurb?.type;
          if (type === 'paragraph') {
            return {
              type,
              content:
                blurb?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                blurb?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
              title: blurb?.rating_blurb_section_paragraph?.title,
            };
          }
          if (type === 'table') {
            return {
              type,
              content: blurb?.rating_blurb_section_table?.rating_table_attributes
                ?.find(({ name }) => name === 'type')
                ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                ?.map((item: any, index: number) => {
                  return {
                    type: item?.value,
                    status: blurb?.rating_blurb_section_table?.rating_table_attributes
                      ?.find(({ name }) => name === 'status')
                      ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)?.[index]
                      ?.value,
                    description:
                      blurb?.rating_blurb_section_table?.rating_table_attributes
                        ?.find(({ name }) => name === 'description')
                        ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                        ?.[index]?.ckeditor_content_doc?.content?.trim() ??
                      blurb?.rating_blurb_section_table?.rating_table_attributes
                        ?.find(({ name }) => name === 'description')
                        ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                        ?.[index]?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
                  };
                }),
              tableType: blurb?.section_type,
              caption:
                blurb?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                blurb?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
            };
          }
        }),
        readMoreContent: section?.children?.[0]?.rating_blurb_sections
          ?.sort((a: any, b: any) => a?.order - b?.order || a.id - b.id)
          ?.map((section: any) => {
            const type = section?.type;
            if (type === 'paragraph') {
              return {
                type,
                content:
                  section?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                  section?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
                title: section?.rating_blurb_section_paragraph?.title,
              };
            }
            if (type === 'timeline_table') {
              const timelineDescription =
                section?.children
                  ?.find(({ type }) => type === 'paragraph')
                  ?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                section?.children
                  ?.find(({ type }) => type === 'paragraph')
                  ?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim();
              const timelineArray = section?.rating_blurb_section_table?.rating_table_attributes
                ?.find(({ name }) => name === 'timeline_date')
                ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                ?.map((item: any, index: number) => {
                  return {
                    date: item?.value,
                    description:
                      section?.rating_blurb_section_table?.rating_table_attributes
                        ?.find(({ name }) => name === 'description')
                        ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                        ?.[index]?.ckeditor_content_doc?.content?.trim() ??
                      section?.rating_blurb_section_table?.rating_table_attributes
                        ?.find(({ name }) => name === 'description')
                        ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                        ?.[index]?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
                  };
                })
                ?.sort((a: any, b: any) => {
                  return (
                    new Date(a?.date?.split(':|:')?.[2]?.split(',')?.[0]).getTime() -
                    new Date(b?.date?.split(':|:')?.[2]?.split(',')?.[0]).getTime()
                  );
                });
              return {
                timelineDescription,
                timelineArray,
                type,
              };
            }
            if (type === 'table') {
              return {
                type,
                content: section?.rating_blurb_section_table?.rating_table_attributes
                  ?.find(({ name }) => name === 'type')
                  ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                  ?.map((item: any, index: number) => {
                    return {
                      type: item?.value,
                      status: section?.rating_blurb_section_table?.rating_table_attributes
                        ?.find(({ name }) => name === 'status')
                        ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)?.[
                        index
                      ]?.value,
                      description:
                        section?.rating_blurb_section_table?.rating_table_attributes
                          ?.find(({ name }) => name === 'description')
                          ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                          ?.[index]?.ckeditor_content_doc?.content?.trim() ??
                        section?.rating_blurb_section_table?.rating_table_attributes
                          ?.find(({ name }) => name === 'description')
                          ?.rating_table_data?.sort((a: any, b: any) => a?.row_id - b?.row_id)
                          ?.[
                            index
                          ]?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
                    };
                  }),
                tableType: section?.section_type,
                caption:
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
              };
            }
            if (type === 'title') {
              return {
                type,
                content: section?.rating_blurb_section_title?.title,
                titleType: section?.rating_blurb_section_title?.title_type,
              };
            }
            if (type === 'image') {
              return {
                type,
                imageUrl: section?.rating_blurb_section_image?.image_url,
                imageAltText: section?.rating_blurb_section_image?.alttext,
                description:
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
              };
            }
            if (type === 'map') {
              return {
                type,
                maptilerLayers: section?.rating_blurb_section_map?.maptiler_layers,
                maptilerViewState: section?.rating_blurb_section_map?.maptiler_viewstate,
                maptilerLink: section?.rating_blurb_section_map?.maptiler_link,
                description:
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
              };
            }
            if (type === 'chart') {
              return {
                type,
                description:
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.content?.trim() ??
                  section?.children?.[0]?.rating_blurb_section_paragraph?.ckeditor_content_doc?.shared_content_paragraph?.content?.trim(),
                chartConfig: section?.rating_blurb_section_chart?.rating_chart_config?.config,
                chartData: section?.rating_blurb_section_chart?.rating_chart_data,
                isHidden: section?.rating_blurb_section_chart?.is_hidden,
                chartType: section?.rating_blurb_section_chart?.rating_chart_config?.chart_type,
                title: section?.rating_blurb_section_chart?.title,
                uniqueIdentifier: section?.rating_blurb_section_chart?.rating_blurb_section_id,
              };
            }
            if (type === 'html') {
              return {
                type,
                htmlContent: section?.rating_blurb_section_html?.html,
                title: section?.rating_blurb_section_html?.title,
              };
            }
          }),
      };
    });

  return {
    ghgSummaryParagraph,
    ratingSections,
    isDetailedDataAvailable: true,
  };
};

export const getChartTypeCustomizations = (chartType: string, isLg: boolean) => {
  if (chartType === 're_overcrediting_baseline_1') {
    return {
      ...(isLg
        ? {
            grid: {
              bottom: 100,
            },
          }
        : {}),
    };
  }
  return {};
};

export const getXAxis = (chartConfig: any, rowData: any, chartType: any) => {
  if (
    chartType === 'redd_overcrediting_baseline_3' ||
    chartType === 'redd_overcrediting_baseline_2'
  ) {
    return {
      ...chartConfig?.xAxis,
      data: rowData.map((d) => d.type),
    };
  }
  if (chartType === 'ar_chart_3') {
    return {
      ...chartConfig?.xAxis,
      axisLabel: {
        formatter: function (value: string) {
          const maxLength = 10;
          const rows: string[] = [];
          let currentLine = '';
          const words: string[] = value.split(' ');

          for (let i = 0; i < words.length; i++) {
            if (
              currentLine.length + words[i].length + (currentLine.length > 0 ? 1 : 0) <=
              maxLength
            ) {
              currentLine += (currentLine.length > 0 ? ' ' : '') + words[i];
            } else {
              if (currentLine.length > 0) {
                rows.push(currentLine);
              }
              currentLine = words[i];
            }
          }
          rows.push(currentLine);

          return rows.join('\n');
        },
      },
    };
  }

  return chartConfig?.xAxis;
};

export const getSeriesData = (
  chartConfig: any,
  rowData: any,
  chartType: any,
  country = 'Brazil',
  state: any
) => {
  if (chartType === 'redd_overcrediting_baseline_1') {
    const projectAppliedReferencePeriod = rowData
      .filter((r) => r.project_applied_reference_period)
      .sort((x) => x.year);
    const projectAppliedReferenceStartingYear = projectAppliedReferencePeriod[0]?.year;
    const projectAppliedReferenceEndingYear = projectAppliedReferencePeriod.at(-1)?.year;
    const projectAppliedReferenceStartingIndex = rowData.findIndex(
      (r) => r.year === projectAppliedReferenceStartingYear
    );
    const projectAppliedReferenceEndingIndex = rowData.findIndex(
      (r) => r.year === projectAppliedReferenceEndingYear
    );

    const calyxReferencePeriod = rowData
      .filter((r) => r.calyx_reference_period)
      .sort((x) => x.year);
    const calyxReferenceStartingYear = calyxReferencePeriod[0]?.year;
    const calyxReferenceEndingYear = calyxReferencePeriod.at(-1)?.year;
    const calyxReferenceStartingIndex = rowData.findIndex(
      (r) => r.year === calyxReferenceStartingYear
    );
    const calyxReferenceEndingIndex = rowData.findIndex((r) => r.year === calyxReferenceEndingYear);

    const markAreaSeries = [] as any;
    if (projectAppliedReferenceStartingIndex !== -1 && projectAppliedReferenceEndingIndex !== -1) {
      markAreaSeries.push({
        name: 'Project Applied Reference Period',
        type: 'bar',
        data: [],
        itemStyle: {
          color: 'rgba(197, 162, 203, 0.4)',
        },
        markArea: {
          data: [
            [
              {
                name: '',
                xAxis: projectAppliedReferenceStartingIndex,
                itemStyle: {
                  color: 'rgba(197, 162, 203, 0.4)',
                },
              },
              {
                xAxis: projectAppliedReferenceEndingIndex,
              },
            ],
          ],
        },
      });
    }
    if (calyxReferenceStartingIndex !== -1 && calyxReferenceEndingIndex !== -1) {
      markAreaSeries.push({
        name: 'Calyx Reference Period',
        type: 'bar',
        data: [],
        itemStyle: {
          color: 'rgba(113, 176, 115, 0.4)',
        },
        markArea: {
          data: [
            [
              {
                name: '',
                xAxis: calyxReferenceStartingIndex,
                itemStyle: {
                  color: 'rgba(113, 176, 115, 0.4)',
                },
              },
              {
                xAxis: calyxReferenceEndingIndex,
              },
            ],
          ],
        },
      });
    }

    const markLineSeries = [] as any;
    const projectStartDate = rowData.find((r) => r.project_start)?.year;
    const projectStartDateIndex = rowData.findIndex((r) => r.year === projectStartDate);
    if (projectStartDateIndex !== -1) {
      markLineSeries.push({
        name: '',
        type: 'bar',
        data: [],
        markLine: {
          data: [
            {
              name: '',
              xAxis: projectStartDateIndex,
              label: {
                formatter: '{b}',
                position: 'middle',
              },
              itemStyle: {
                color: 'rgba(255, 255, 255, 1)',
              },
              symbol: 'none',
            },
          ],
        },
      });
    }
    return chartConfig.series
      .map((series, index) => {
        if (
          index === 2 &&
          rowData.map((r) => r.project_defined_reference_region).filter((f) => f).length === 0
        ) {
          return null;
        }
        return series;
      })
      .filter((s) => s)
      .concat(markAreaSeries)
      .concat(markLineSeries);
  } else if (chartType === 'redd_overcrediting_baseline_2') {
    return chartConfig.series.map(() => {
      const colors = ['#2A3A8E', '#8F2913', '#4661EC', '#EE4520'];

      return {
        type: 'bar',
        data: rowData.map((row, index) => {
          return {
            value: row.forest_loss,
            itemStyle: {
              color: colors[index],
            },
          };
        }),
      };
    });
  } else if (chartType === 'redd_overcrediting_baseline_3') {
    return chartConfig.series.map(() => {
      const colors = ['#2A3A8E', '#384DBD', '#4661EC', '#37528E'];
      return {
        type: 'bar',
        data: rowData.map((row, index) => {
          return {
            value: Number(row?.forest_loss?.replace?.('%', '')),
            itemStyle: {
              color: colors[index],
            },
          };
        }),
      };
    });
  } else if (chartType === 'redd_overcrediting_baseline_4') {
    return chartConfig.series
      .map((series, index) => {
        const colors = ['#2A3A8E', '#EE4520', '#FFCB14', '#8F2913'];

        if (
          index === 1 &&
          rowData.map((r) => r.project_observed_degradation).filter((f) => f).length === 0
        ) {
          return null;
        }
        if (rowData.map((r) => r[series.dimensions[1]]).filter(Boolean).length === 0) return null;

        return {
          ...series,
          itemStyle: {
            color: colors[index],
          },
        };
      })
      .filter((f) => f);
  } else if (chartType === 'redd_permanence_3') {
    return chartConfig.series.map(() => {
      return {
        type: 'scatter',
        data: rowData.map((row) => {
          if (row.country === country) {
            return {
              name: row.country,
              value: [Number(row.government_effectiveness), Number(row.strength_civil_society)],
              itemStyle: {
                color: '#ffff00',
              },
            };
          }

          return {
            name: row.country,
            value: [Number(row.government_effectiveness), Number(row.strength_civil_society)],
          };
        }),
      };
    });
  } else if (chartType === 'car_mexico_chart_8') {
    return chartConfig.series.map(() => {
      return {
        type: 'scatter',
        data: rowData.map((row) => {
          if (row.state === state) {
            return {
              name: row.state,
              value: [Number(row.government_average), Number(row.civil_average)],
              itemStyle: {
                color: '#ffff00',
              },
            };
          }

          return {
            name: row.state,
            value: [Number(row.government_average), Number(row.civil_average)],
          };
        }),
      };
    });
  } else if (chartType === 're_additionality_common_practice_2') {
    return uniq(rowData.map((row) => row.location)).map((location) => {
      return {
        ...chartConfig.series[0],
        name: location,
        data: rowData
          .filter((row) => row.location === location)
          .map((row) => {
            return [Number(row.year), Math.round(Number(row.penetration_rate) * 100) / 100];
          }),
      };
    });
  } else if (
    chartType === 'ar_chart_2' ||
    chartType === 're_overcrediting_baseline_1' ||
    chartType === 'car_mexico_chart_1'
  ) {
    return chartConfig.series
      .map((series) => {
        if (rowData.map((r) => r[series.dimensions[1]]).filter((f) => f).length === 0) {
          return null;
        }

        return series;
      })
      .filter((s) => s);
  } else if (chartType === 'ar_chart_1') {
    const markAreaSeries = [] as any;
    const projectStartDate = rowData.find((r) => r.project_start)?.year;
    const projectStartDateIndex = rowData.findIndex((r) => r.year === projectStartDate);
    if (projectStartDateIndex !== -1) {
      markAreaSeries.push({
        name: '',
        type: 'bar',
        data: [],
        silent: true,
        markArea: {
          data: [
            [
              {
                name: '',
                xAxis: 0,
                itemStyle: {
                  color: 'rgba(113, 176, 115, 0.4)',
                },
              },
              {
                xAxis: projectStartDateIndex,
              },
            ],
          ],
        },
      });
    }

    return chartConfig.series.concat(markAreaSeries);
  } else if (chartType === 'ar_chart_5') {
    const currentProjectIndex = rowData.findIndex((r) => r.current_project == '1');
    return [
      {
        ...chartConfig.series[0],
        data: rowData.map((row, index) => {
          if (index === currentProjectIndex) {
            return {
              value: [row.years, row.projects_count],
              itemStyle: {
                color: '#FFCB14',
              },
            };
          }
          return [row.years, row.projects_count];
        }),
      },
    ];
  } else if (chartType === 'redd_permanence_1') {
    const currentProjectIndex = rowData.findIndex((r) => r.current_project == '1');
    return [
      {
        ...chartConfig.series[0],
        data: rowData.map((row, index) => {
          const yAxisValue = row.proportion_of_redd_projects?.includes('%')
            ? row.proportion_of_redd_projects?.split('%')?.[0]
            : row.proportion_of_redd_projects;
          if (index === currentProjectIndex) {
            return {
              value: [row.period, yAxisValue],
              itemStyle: {
                color: '#FFCB14',
              },
            };
          }
          return [row.period, yAxisValue];
        }),
      },
    ];
  } else if (chartType === 'manure_management_chart_2') {
    const currentProjectIndex = rowData.findIndex((r) => r.is_project_value === '1');
    const thirdMonthIndex = rowData.findIndex((r) => r.retention_time == '3');

    const markPointData = [] as Array<{
      coord: [any, number];
      itemStyle: {
        color: string;
      };
    }>;
    if (thirdMonthIndex > -1) {
      markPointData.push({
        coord: [
          rowData[thirdMonthIndex]?.retention_time,
          Number(rowData[thirdMonthIndex]?.mcf?.replace('%', '').replace(',', '')),
        ],
        itemStyle: {
          color: '#22C11F',
        },
      });
    }
    if (currentProjectIndex > -1) {
      markPointData.push({
        coord: [
          rowData[currentProjectIndex]?.retention_time,
          Number(rowData[currentProjectIndex]?.mcf?.replace('%', '').replace(',', '')),
        ],
        itemStyle: {
          color: '#EE4520',
        },
      });
    }

    return [
      {
        ...chartConfig.series[0],
        data: rowData
          .map((row, index) => {
            if (index === currentProjectIndex) {
              return null;
            }
            return [row.retention_time, Number(row.mcf?.replace('%', '').replace(',', ''))];
          })
          .filter((f) => f),
        markPoint: {
          symbol: 'circle',
          symbolSize: 10,
          data: markPointData,
        },
      },
    ];
  } else if (chartType === 'ar_chart_3') {
    return [
      {
        ...chartConfig.series[0],
        itemStyle: {
          normal: {
            color: function (params) {
              if (params.data.type === 'Project') {
                return '#FFCB13';
              }
              return '#4992ff';
            },
          },
        },
      },
    ];
  }

  return chartConfig?.series;
};

export const getTableData = (rowData: any, chartType: any) => {
  if (chartType === 'cs_overcrediting_fnrb') {
    return {
      source: rowData.map((row) => ({
        ...row,
        fnrb: Number(row?.fnrb?.replace?.('%', '')),
      })),
    };
  } else if (chartType === 're_additionality_country_context') {
    return {
      source: rowData.map((row) => ({
        ...row,
        non_retired_carbon_credits: Number(row?.non_retired_carbon_credits?.replace?.('%', '')),
      })),
    };
  } else if (chartType === 're_overcrediting_baseline') {
    return {
      source: rowData.map((row) => ({
        ...row,
        actual_gef: Number(row?.actual_gef?.replace?.('%', '')),
        project_gef: Number(row?.project_gef?.replace?.('%', '')),
      })),
    };
  } else if (chartType?.startsWith('re_additionality_common_practice_1')) {
    return {
      source: rowData.map((row) => ({
        ...row,
        year: Number(row?.year),
        energy_other_sources: Number(row?.energy_other_sources),
        specific_tech_installed_capacity: Number(row?.specific_tech_installed_capacity),
        penetration_rate: Number(row?.penetration_rate),
      })),
    };
  } else if (chartType?.startsWith('re_additionality_common_practice_2')) {
    return {
      source: rowData.map((row) => ({
        ...row,
        year: Number(row?.year),
        penetration_rate: Number(row?.penetration_rate),
      })),
    };
  } else if (chartType?.startsWith('re_additionality_common_practice_3')) {
    return {
      source: rowData.map((row) => ({
        ...row,
        // year: Number(row?.year),
        energy_other_sources: Number(row?.energy_other_sources),
        specific_tech_installed_capacity: Number(row?.specific_tech_installed_capacity),
      })),
    };
  } else if (chartType === 'redd_overcrediting_baseline_1') {
    return {
      source: rowData.map((row) => ({
        ...row,
        year: Number(row?.year),
        project_area: Number(row?.project_area?.replace?.('%', '')),
        buffer_area: Number(row?.buffer_area?.replace?.('%', '')),
        project_defined_reference_region: Number(
          row?.project_defined_reference_region?.replace?.('%', '').replace?.(',', '')
        ),
      })),
    };
  } else if (chartType === 'redd_overcrediting_baseline_2') {
    return {
      source: rowData.map((row) => ({
        ...row,
      })),
    };
  } else if (chartType === 'redd_overcrediting_baseline_3') {
    return {
      source: rowData.map((row) => ({
        ...row,
        forest_loss: Number(row?.forest_loss?.replace?.('%', '')),
      })),
    };
  } else if (chartType === 'redd_permanence_1') {
    return {
      source: rowData.map((row) => ({
        ...row,
        proportion_of_redd_projects: Number(row?.proportion_of_redd_projects?.replace?.('%', '')),
      })),
    };
  } else if (chartType === 'redd_permanence_3') {
    return {
      source: rowData.map((row) => ({
        ...row,
        government_effectiveness: Number(row?.government_effectiveness?.replace?.('%', '')),
        strength_civil_society: Number(row?.strength_civil_society?.replace?.('%', '')),
      })),
    };
  } else if (chartType === 'ar_chart_1') {
    return {
      source: rowData.map((row) => ({
        ...row,
        planted_forest: row?.planted_forest
          ? Number(row?.planted_forest?.replace?.(',', ''))
          : null,
      })),
    };
  } else if (chartType === 'ar_chart_2') {
    return {
      source: rowData.map((row) => ({
        ...row,
        project_cumulative_removal: Number(row?.project_cumulative_removal?.replace?.(',', '')),
        winrock_teak_removals: Number(row?.winrock_teak_removals?.replace?.(',', '')),
        winrock_other_confier_removals: Number(
          row?.winrock_other_confier_removals?.replace?.(',', '')
        ),
        winrock_eucalyptus_removals: Number(row?.winrock_eucalyptus_removals?.replace?.(',', '')),
      })),
    };
  } else if (chartType === 'ar_chart_4') {
    return {
      source: rowData.map((row) => ({
        ...row,
        achieved_net_ghg_er: Number(row?.achieved_net_ghg_er?.replace?.(',', '')),
        estimated_net_ghg_er: Number(row?.estimated_net_ghg_er?.replace?.(',', '')),
      })),
    };
  } else if (chartType === 'car_mexico_chart_8') {
    return {
      source: rowData.map((row) => ({
        ...row,
        government_average: Number(row?.government_average?.replace?.(',', '')).toFixed(2),
        civil_average: Number(row?.civil_average?.replace?.(',', '')).toFixed(2),
      })),
    };
  } else if (chartType === 'dry_forest_chart_1') {
    return {
      source: rowData.map((row) => ({
        ...row,
        project_defined_area: Number(row?.project_defined_area?.replace?.('%', '')),
        project_area: Number(row?.project_area?.replace?.('%', '')),
      })),
    };
  } else if (chartType === 'manure_management_chart_1') {
    return {
      source: rowData.map((row) => ({
        ...row,
        delta_irr: Number(row?.delta_irr?.replace?.('%', '')),
      })),
    };
  } else if (chartType === 'manure_management_chart_2') {
    return {
      source: rowData.map((row) => ({
        ...row,
        mcf: Number(row?.mcf?.replace?.('%', '')),
      })),
    };
  }

  return {
    source: rowData.map((row) => ({
      ...row,
    })),
  };
};

export const getChipInfoFromStatusNumber = (status: number, mode: string) => {
  let icon: React.ReactNode | null = <></>;
  switch (status) {
    case 0:
      icon = (
        <Chip
          variant='outlined'
          size='md'
          sx={(theme) => {
            return {
              border: `1px solid ${mode === 'dark' ? dark.SDG[5] : light.SDG[5]}`,
              color: theme.palette.success.outlinedColor,
              fontWeight: 500,
            };
          }}
        >
          Low Risk
        </Chip>
      );
      break;
    case 1:
      icon = (
        <Chip
          variant='outlined'
          size='md'
          sx={(theme) => {
            return {
              border: `1px solid ${mode === 'dark' ? dark.SDG[5] : light.SDG[5]}`,
              color: theme.palette.success.outlinedColor,
              fontWeight: 500,
            };
          }}
        >
          Low-Medium Risk
        </Chip>
      );
      break;
    case 2:
      icon = (
        <Chip
          variant='outlined'
          size='md'
          sx={(theme) => {
            return {
              border: `1px solid ${theme.palette.warning.outlinedBorder}`,
              color: theme.palette.warning.outlinedColor,
              fontWeight: 500,
            };
          }}
        >
          Medium Risk
        </Chip>
      );
      break;
    case 3:
      icon = (
        <Chip
          variant='outlined'
          size='md'
          sx={(theme) => {
            return {
              border: `1px solid ${theme.palette.warning.outlinedBorder}`,
              color: theme.palette.warning.outlinedColor,
              fontWeight: 500,
            };
          }}
        >
          Medium-High Risk
        </Chip>
      );
      break;
    case 4:
      icon = (
        <Chip
          variant='outlined'
          size='md'
          sx={(theme) => {
            return {
              border: `1px solid ${theme.palette.danger.outlinedBorder}`,
              color: theme.palette.danger.outlinedColor,
              fontWeight: 500,
            };
          }}
        >
          High Risk
        </Chip>
      );
      break;
    case 5:
      icon = (
        <Box position={'relative'} top={4}>
          <FaCheckCircle color={theme.palette.primary.outlinedActiveBg} size={24} />
        </Box>
      );
      break;
    case 6:
      icon = (
        <Box position={'relative'} top={4}>
          <FaMinusCircle color={mode === 'dark' ? dark.GHG['C+'] : dark.GHG['C+']} size={24} />
        </Box>
      );
      break;
    case 7:
      icon = (
        <Box position={'relative'} top={4}>
          <IoMdCloseCircle color={mode === 'dark' ? dark.GHG['E'] : dark.GHG['E']} size={24} />
        </Box>
      );
      break;
    case 8:
      icon = (
        <Chip
          size='md'
          sx={(theme) => {
            return {
              border: `1px solid ${theme.palette.neutral.outlinedBorder}`,
              color: theme.palette.neutral.outlinedColor,
              fontWeight: 500,
            };
          }}
        >
          N/A
        </Chip>
      );
      break;

    default:
      icon = null;
      break;
  }
  return { icon };
};
