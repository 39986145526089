export const getSDGImpactRatingDetail = (rating) => {
  switch (rating) {
    case 1:
      return 'Low impact';
    case 2:
      return 'Low - medium impact';
    case 3:
      return 'Medium impact';
    case 4:
      return 'Medium - high impact';
    case 5:
      return 'High impact';
    default:
      return '';
  }
};
