import { Box, Divider, Stack, Typography } from '@mui/joy';

import { formatTimelineDate } from '../../../../utils/functions/date';
import useIsLargeScreen from '../../../../utils/hooks/useIsLargeScreen';

import { HTMLRenderer } from './HTMLRenderer';

interface PropTypes {
  timelineArray: Array<{ date: string; description: string }>;
  timelineDescription: string;
}

export const TimelineComponent = ({ timelineArray, timelineDescription }: PropTypes) => {
  const isLg = useIsLargeScreen();
  return (
    <>
      <Typography level='h2' color='primary'>
        Timeline
      </Typography>
      <Box display={'grid'} gridTemplateColumns={isLg ? '3fr 0fr 2fr' : '1fr'} gap={isLg ? 6 : 2}>
        <Stack spacing={1}>
          {timelineArray?.map(({ date, description }, index) => {
            const displayDate = formatTimelineDate(date);
            return (
              <Stack spacing={1} direction={'row'} key={`${index}-${displayDate}`}>
                <Stack alignItems={'center'} spacing={1}>
                  <Box
                    height={'24px'}
                    width={'24px'}
                    padding={'10px'}
                    borderRadius={'100%'}
                    position={'relative'}
                    border={`1px solid gray`}
                  >
                    <Box
                      borderRadius={'100%'}
                      width={'12px'}
                      height={'12px'}
                      bgcolor={'gray'}
                      position={'absolute'}
                      top='50%'
                      left='50%'
                      sx={{ transform: 'translate(-50%, -50%)' }}
                    />
                  </Box>
                  {index !== timelineArray?.length - 1 ? (
                    <Box width={'1px'} minHeight={'42px'} bgcolor={'gray'} />
                  ) : null}
                </Stack>
                <Stack>
                  <Typography fontWeight={'xl'} fontSize={'lg'} color='primary'>
                    {displayDate}
                  </Typography>
                  <Typography fontWeight={'sm'} fontSize={'md'} color='neutral'>
                    <HTMLRenderer
                      dangerouslySetInnerHTML={{ __html: description }}
                      colorType='secondary'
                    />
                  </Typography>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
        <Divider orientation='vertical' />
        <Typography color='neutral' fontSize={'md'} fontWeight={'md'}>
          <HTMLRenderer
            colorType='secondary'
            dangerouslySetInnerHTML={{ __html: timelineDescription }}
          />
        </Typography>
      </Box>
    </>
  );
};
