import { formatNumberWithCommas } from '../../../../../utils/functions/number';

const currentYear = new Date().getFullYear();

export const retireeInsightsAGGridColumnsDefs: any[] = [
  {
    headerName: 'Company',
    filter: 'RetireeFilter',
    suppressHeaderMenuButton: true,
    width: 200,
    pinned: 'left',
    field: 'retiree',
    colId: 'retiree',
    wrapText: true,
    autoHeight: true,
    cellStyle: {
      wordBreak: 'normal',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  {
    headerName: 'Volume (tCO2e) by retirement year',
    headerClass: 'volume-by-retirement-year-header',
    suppressHeaderMenuButton: true,
    filter: false,
    children: [
      {
        headerName: `<= ${currentYear - 4}`,
        field: 'volume.v_year_minus_4',
        colId: 'volume.v_year_minus_4',
        headerClass: 'ag-right-aligned-header',
        suppressHeaderMenuButton: true,
        wrapText: true,
        autoHeight: true,
        cellStyle: {
          textAlign: 'right',
        },
        cellRenderer: function (params) {
          return formatNumberWithCommas(params.data.volume.v_year_minus_4);
        },
      },
      {
        headerName: `${currentYear - 3}`,
        field: 'volume.v_year_minus_3',
        colId: 'volume.v_year_minus_3',
        headerClass: 'ag-right-aligned-header',
        suppressHeaderMenuButton: true,
        wrapText: true,
        autoHeight: true,
        cellStyle: {
          textAlign: 'right',
        },
        cellRenderer: function (params) {
          return formatNumberWithCommas(params.data.volume.v_year_minus_3);
        },
      },
      {
        headerName: `${currentYear - 2}`,
        field: 'volume.v_year_minus_2',
        colId: 'volume.v_year_minus_2',
        headerClass: 'ag-right-aligned-header',
        suppressHeaderMenuButton: true,
        wrapText: true,
        autoHeight: true,
        cellStyle: {
          textAlign: 'right',
        },
        cellRenderer: function (params) {
          return formatNumberWithCommas(params.data.volume.v_year_minus_2);
        },
      },
      {
        headerName: `${currentYear - 1}`,
        field: 'volume.v_year_minus_1',
        colId: 'volume.v_year_minus_1',
        headerClass: 'ag-right-aligned-header',
        suppressHeaderMenuButton: true,
        wrapText: true,
        autoHeight: true,
        cellStyle: {
          textAlign: 'right',
        },
        cellRenderer: function (params) {
          return formatNumberWithCommas(params.data.volume.v_year_minus_1);
        },
      },
      {
        headerName: `${currentYear} YTD`,
        field: 'volume.v_current_year',
        colId: 'volume.v_current_year',
        headerClass: 'ag-right-aligned-header',
        suppressHeaderMenuButton: true,
        wrapText: true,
        autoHeight: true,
        cellStyle: {
          textAlign: 'right',
        },
        cellRenderer: function (params) {
          return formatNumberWithCommas(params.data.volume.v_current_year);
        },
      },
    ],
  },
  {
    headerName: 'Total(tCO2e)',
    width: 200,
    pinned: 'right',
    suppressHeaderMenuButton: true,
    sortable: true,
    filter: false,
    sort: 'desc',
    wrapText: true,
    autoHeight: true,
    headerClass: 'ag-right-aligned-header',
    valueGetter: function (params) {
      const volumeValues: number[] = Object.values(params.data.volume);
      const sum = volumeValues.reduce((acc: number, value: number) => acc + value, 0);
      return {
        formattedValue: formatNumberWithCommas(sum),
        unformattedValue: sum,
      };
    },
    comparator: function (valueA, valueB) {
      const unformattedValueA = valueA.unformattedValue;
      const unformattedValueB = valueB.unformattedValue;

      if (unformattedValueA === unformattedValueB) {
        return 0;
      }
      return unformattedValueA > unformattedValueB ? 1 : -1;
    },
    cellStyle: {
      textAlign: 'right',
    },
    cellRenderer: function (params) {
      return params.value.formattedValue;
    },
  },
];
