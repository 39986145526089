import { getProjectTypeColor } from '../../../home/constants';
import { getGHGratingColorV2, getSDGRatingColorV2 } from '../../../search/constants';

import { BreakdownByTypes } from './BreakdownCharts';

const colors = [
  '#6B8E23',
  '#4682B4',
  '#D2691E',
  '#8FBC8F',
  '#DAA520',
  '#B0C4DE',
  '#20B2AA',
  '#778899',
  '#7B68EE',
  '#9ACD32',
  '#CD5C5C',
  '#FF8C00',
  '#BA55D3',
  '#9370DB',
  '#3CB371',
  '#40E0D0',
  '#FF6347',
  '#FFB6C1',
  '#FFD700',
  '#6A5ACD',
  '#ADFF2F',
  '#FF4500',
  '#D2B48C',
  '#8A2BE2',
  '#5F9EA0',
  '#D8BFD8',
  '#2E8B57',
  '#FF7F50',
  '#FF1493',
  '#48D1CC',
  '#C71585',
  '#7FFFD4',
  '#DC143C',
  '#FFA07A',
  '#8B0000',
  '#B22222',
  '#8B4513',
  '#DA70D6',
  '#BDB76B',
  '#556B2F',
  '#8FBC8F',
  '#483D8B',
  '#FF00FF',
  '#EE82EE',
  '#98FB98',
  '#66CDAA',
  '#9932CC',
  '#FA8072',
  '#D3D3D3',
  '#4682B4',
];

export const getChartDataItemColor = (
  breakdownType: BreakdownByTypes,
  key: string,
  isDarkMode: boolean,
  index: number
) => {
  switch (breakdownType) {
    case 'project_category':
      return key === 'Unknown' || key === 'Not Rated'
        ? '#999'
        : getProjectTypeColor(key, isDarkMode) ?? colors[index];
    case 'ghg_rating':
      return key === 'Unknown' || key === 'Not Rated'
        ? '#999'
        : getGHGratingColorV2(key, isDarkMode) ?? colors[index];
    case 'sdg_rating':
      return key === 'Unknown' || key === 'Not Rated'
        ? '#999'
        : getSDGRatingColorV2(key, isDarkMode) ?? colors[index];
    default:
      return key === 'Unknown' || key === 'Not Rated' ? '#999' : colors[index];
  }
};
