import { useMemo, useRef, useState } from 'react';

import {
  Box,
  Button,
  Card,
  Grid,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Tooltip,
  Typography,
} from '@mui/joy';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { FaLock } from 'react-icons/fa';
import { IoMdLock } from 'react-icons/io';
import { TbBulb } from 'react-icons/tb';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Image from '../../../../components/Image';
import { StyledLink } from '../../../../components/styled/StyledLink';
import { track } from '../../../../services/analytics';
import {
  EVENT_SDG_SUMMARY_PAGE,
  EVNTAPP_view_deep_dive_click,
  PGVAPP_deep_dive_content_viewed,
  PGVAPP_upgrade_modal_viewed,
} from '../../../../services/analytics/events';
import { getRatingUrl } from '../../../../services/axios/endpoints';
import { projectState } from '../../../../stores/project';
import { useExecuteCallbackOnIntersection } from '../../../../utils/hooks/useExecuteCallbackOnIntersection';
import { useFetchUserPermissions } from '../../../../utils/hooks/useFetchUserPermission';
import { ReadMoreFallback } from '../../ghg_rating/components/readMoreSection/ReadMoreFallback';

import SdgSection from './SDGSection';

const SDG = ({ title, tooltip, data }: { title: string; tooltip: string; data: string[] }) => {
  return (
    <Card sx={{ mb: 2, background: (theme) => theme.palette.primary.outlinedColor }}>
      <Stack direction={'row'} alignItems={'center'} gap={1} sx={{ mb: 1 }}>
        <Typography level='body-xs' fontWeight={'xl'} color='primary'>
          {title}
        </Typography>
        <Tooltip title={tooltip}>
          <InfoCircledIcon />
        </Tooltip>
      </Stack>
      <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
        {data.map((sdg) => {
          const url = getRatingUrl(`E-WEB-Goal-SDG_${sdg}.svg`);
          return (
            <Box sx={{ width: '64px', height: '64px', borderRadius: 4, overflow: 'hidden' }}>
              <Image
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src={url}
                alt=''
              />
            </Box>
          );
        })}
      </Stack>
    </Card>
  );
};

const SdgDetail = ({ data }: { data: any }) => {
  const location = useLocation();
  const { projectData, projectCreditingData } = useRecoilValue(projectState);
  const { hasPermissionForReadMore } = useFetchUserPermissions();
  const [showSubscriptionNotice, setShowSubscriptionNotice] = useState(false);
  const sdgDetailRef = useRef<HTMLDivElement>(null);
  const confirmedSdgs = useMemo(
    () => (data?.sections?.confirmedSdgs || []).map((d) => d.sdg),
    [data]
  );
  const insufficientEvidenceSdgs = useMemo(
    () => (data?.sections?.insufficientEvidenceSdgs || []).map((d) => d.sdg),
    [data]
  );
  const ratedUnderDifferentSds = useMemo(
    () => (data?.sections?.ratedUnderDifferentSds || []).map((d) => d.sdg),
    [data]
  );

  const handleReadMore = () => {
    track(EVNTAPP_view_deep_dive_click, EVENT_SDG_SUMMARY_PAGE, {
      project_id: projectData?.projectId,
      page_url: `${location.pathname}${location.search}`,
      project_tab: 'SDG',
      project_name: projectData?.title,
    });
    sdgDetailRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };

  const handleNotAllowedReadMore = () => {
    setShowSubscriptionNotice((d) => !d);
  };

  useExecuteCallbackOnIntersection(
    sdgDetailRef,
    () => {
      track(
        hasPermissionForReadMore ? PGVAPP_deep_dive_content_viewed : PGVAPP_upgrade_modal_viewed,
        EVENT_SDG_SUMMARY_PAGE,
        {
          project_id: projectData?.projectId,
          project_tab: 'SDG',
          project_name: projectData?.title,
        }
      );
    },
    data,
    '-200px'
  );

  return (
    <>
      <Modal open={showSubscriptionNotice} onClose={handleNotAllowedReadMore}>
        <ModalDialog maxWidth={'500px'}>
          <ModalClose />
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'column'}
            gap={2}
          >
            <Stack alignItems={'center'} direction={'row'} spacing={1}>
              <FaLock size={24} />
              <Typography level='h3'>Read More</Typography>
            </Stack>
            <Typography fontSize={'md'} textAlign={'center'} fontWeight={'xl'}>
              This feature requires an Expert/Enterprise-level subscription. Please email us at{' '}
              <StyledLink to='mailto:customersuccess@calyxglobal.com'>
                info@calyxglobal.com
              </StyledLink>{' '}
              to explore access.
            </Typography>
          </Box>
        </ModalDialog>
      </Modal>
      <Card>
        <Grid container sx={{ p: 3 }}>
          <Grid xs={12} lg={6} sx={{ pr: { xs: 0, lg: 3 } }}>
            <Card
              sx={{
                background: (theme) => theme.palette.primary.outlinedColor,
                mb: { xs: 2, lg: 0 },
                width: 1,
              }}
            >
              <Stack gap={2}>
                <Typography level='body-md' color='primary'>
                  Based on the evidence provided in the most recent {data.certificate} monitoring
                  and verification process, Calyx Global has identified that the project contributed
                  to {data.sdgContributions} Sustainable Development{' '}
                  {data.sdgContributions > 1 ? 'Goals (SDGs)' : 'Goal (SDG)'}. Our assessment
                  reveals that the project has contributed to {data.diffContributions} different SDG{' '}
                  {data.diffContributions > 1 ? 'targets' : 'target'} , comprising{' '}
                  {data.explicitContributions} explicit{' '}
                  {data.explicitContributions > 1 ? 'contributions' : 'contribution'} and{' '}
                  {data.implicitContributions} implicit{' '}
                  {data.implicitContributions > 1 ? 'contributions' : 'contribution'} identified by
                  Calyx Global.
                </Typography>
                <Button
                  sx={{
                    background: (theme) => theme.palette['linearGradient']['special'],
                    width: 'fit-content',
                  }}
                  size='sm'
                  startDecorator={<TbBulb />}
                  onClick={handleReadMore}
                >
                  View deep-dive analysis
                </Button>
              </Stack>
            </Card>
          </Grid>
          <Grid xs={12} lg={6}>
            {confirmedSdgs.length > 0 && (
              <SDG
                title='CALYX GLOBAL CONFIRMED SDGS'
                data={confirmedSdgs}
                tooltip='Calyx Global identified relevant and sufficient evidence indicating that the project contributes to the following SDGs.'
              />
            )}
            {ratedUnderDifferentSds.length > 0 && (
              <SDG
                title='RATED UNDER A DIFFERENT SDG'
                data={ratedUnderDifferentSds}
                tooltip={`While a project activity may contribute to multiple SDGs simultaneously, these contributions were neither scored nor considered in the project's SDG Rating.`}
              />
            )}
            {insufficientEvidenceSdgs.length > 0 && (
              <SDG
                title='INSUFFICIENT EVIDENCE'
                data={insufficientEvidenceSdgs}
                tooltip={`The evidence presented in the project documentation does not meet Calyx Global's quality standards. Consequently, Calyx Global cannot confirm the project's contribution to these SDGs.`}
              />
            )}
          </Grid>
        </Grid>
      </Card>
      <Card sx={{ p: 0, overflow: 'hidden' }} ref={sdgDetailRef}>
        <Box
          height={'56px'}
          width={'lg'}
          sx={{ background: (theme) => theme.palette['linearGradient']['special'] }}
          display={'flex'}
          alignItems={'center'}
          paddingX={2}
        >
          <Stack
            direction={'row'}
            gap={1}
            alignItems={'center'}
            sx={(theme) => {
              return { color: theme.palette.common.white };
            }}
          >
            {hasPermissionForReadMore ? <TbBulb size={20} /> : <IoMdLock size={20} />}
            <Typography
              fontSize={'lg'}
              fontWeight={'md'}
              sx={(theme) => {
                return { color: theme.palette.common.white };
              }}
            >
              Deep-Dive Analysis
            </Typography>
          </Stack>
        </Box>
        {hasPermissionForReadMore ? (
          <Box sx={{ p: (theme) => theme.spacing(2) }}>
            <SdgSection
              type='confirmed'
              title='Calyx Global confirmed SDGs'
              description='Carbon projects can make significant contributions to Sustainable Development Goals (SDGs) by mitigating climate change and promoting broader sustainable development objectives. Calyx Global assesses verified project-level contributions to SDGs (i.e., projects that have obtained a sustainability-specific certification like the Climate, Community & Biodiversity Standards, the Sustainable Development Verified Impact Standard, or Gold Standard for the Global Goals). With our SDG Impact framework, we assess both explicit contributions claimed by the project developer and implicit contributions, which are not directly mapped to a specific SDG, but attributable to one. This section highlights SDGs for which our analysis supports impact claims.'
              data={data.sections.confirmedSdgs}
            />
            <SdgSection
              type='rated'
              title='Rated under a different SDG'
              description='To ensure fairness and consistency across projects, we avoid scoring target-level SDG contributions under more than one SDG at a time. Overlapping goals are classified as "Rated under a different SDG” to provide recognition for all verified SDG contributions without artificially inflating project ratings. For example, a project generating employment (SDG 8) to address local poverty (SDG 1) would receive a target-level score for only one activity—let’s say, for the activity of creating jobs under SDG 8. This means that the project will not receive a target-level score under SDG 1. In this section, we provide information on projects that contribute to multiple SDGs simultaneously.'
              data={data.sections.ratedUnderDifferentSds}
            />
            <SdgSection
              type='insufficient'
              title='Insufficient evidence'
              description={`This section displays SDGs to which the project claimed or could contribute but did not meet Calyx Global's quality evidence threshold. Both explicit and implicit contributions are included but clearly differentiated, providing a comprehensive view of potential SDGs contributions. SDGs listed in this section were not factored into the overall project’s SDG Rating.`}
              data={data.sections.insufficientEvidenceSdgs}
            />
          </Box>
        ) : (
          <ReadMoreFallback
            tab='SDG'
            project={projectData}
            projectCreditingData={projectCreditingData}
            selectedTab={''}
          />
        )}
      </Card>
    </>
  );
};

export default SdgDetail;
