import { memo } from 'react';

import { Button, Card, Skeleton, Stack, Typography, styled } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import Image from '../../../components/Image';
import { track } from '../../../services/analytics';
import { EVENT_DASHBOARD_PAGE, EVNTAPP_blog_show_more } from '../../../services/analytics/events';
import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { blogs, getAssetURL } from '../../../services/axios/endpoints';
import { dateFormat } from '../../../utils/functions/date';

const SuggestedItemImage = styled(Image)(({ theme }) => ({
  width: '124px',
  borderRadius: theme.radius.sm,
  objectFit: 'contain',
  backgroundPosition: '50% 50%',
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  borderRadius: theme.radius.sm,
  padding: theme.spacing(1),
  border: `1px solid transparent`,
  '&:hover': {
    backgroundColor: theme.palette.primary.outlinedHoverBg,
  },
  '&:focus, &:active': {
    backgroundColor: theme.palette.primary.outlinedHoverBg,
    border: `1px solid ${theme.palette.focusVisible}`,
  },
}));

interface SuggestedItemPropTypes {
  imageURL: string;
  title: string;
  date: string;
  id: number;
}

export const SuggestedItemLoader = memo(() => {
  return (
    <Stack direction={'row'} gap={2} marginTop={1}>
      <Skeleton variant='rectangular' width={'124px'} height={'78px'} />
      <Stack width={'60%'}>
        <Skeleton variant='text' />
        <Skeleton variant='text' level='body-sm' />
      </Stack>
    </Stack>
  );
});

export const SuggestedItem = memo((props: SuggestedItemPropTypes) => {
  const { date, imageURL, title, id } = props;
  return (
    <StyledLink to={`/blog-detail?tag=${id}`}>
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <SuggestedItemImage alt={title} src={getAssetURL(`${imageURL}`)} />
        <Stack spacing={0.5}>
          <Typography fontSize={'sm'} level='title-sm' fontWeight={'lg'}>
            {title}
          </Typography>
          <Typography fontSize={'sm'} level='body-sm'>
            {dateFormat(date).mmddyyyy}
          </Typography>
        </Stack>
      </Stack>
    </StyledLink>
  );
});

const LatestBlogs = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['blogs'],
    queryFn: async () => await makeAuthenticatedGetRequest(blogs, { params: { limit: 5 } }),
    select: (data) => data?.data?.response,
  });
  const handleBlogShowMore = () => {
    track(EVNTAPP_blog_show_more, EVENT_DASHBOARD_PAGE);
  };

  return (
    <Card variant='outlined' sx={{ height: 'min-content' }} orientation='vertical'>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography
          level='title-lg'
          sx={(theme) => {
            return { color: theme.palette.text.primary };
          }}
        >
          Latest from Calyx Global
        </Typography>
        <Link to='/blog' onClick={handleBlogShowMore}>
          <Button variant='outlined' size='sm' endDecorator={<FaArrowRight />}>
            Show more
          </Button>
        </Link>
      </Stack>
      <Stack spacing={2} mt={2}>
        {isLoading
          ? [...new Array(5).fill('')].map((item, index) => {
              return <SuggestedItemLoader key={index + item} />;
            })
          : (data || []).map((blog: any) => {
              return (
                <SuggestedItem
                  key={blog?.id}
                  title={blog?.title}
                  date={blog?.date}
                  imageURL={blog?.visual}
                  id={blog?.id}
                />
              );
            })}
      </Stack>
    </Card>
  );
};

export { LatestBlogs };
