import { useMemo, useState } from 'react';

import { Button, ButtonGroup, Card, Stack, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { DataTable, TableConfig } from '../../../components/DataTable';
import { track } from '../../../services/analytics';
import {
  EVENT_DASHBOARD_PAGE,
  EVNTAPP_tab_following,
  EVNTAPP_tab_most_issued,
  EVNTAPP_tab_most_retired,
  EVNTAPP_tab_recently_added,
} from '../../../services/analytics/events';
import { makeAuthenticatedGetRequest } from '../../../services/axios';
import { dashboardProjects } from '../../../services/axios/endpoints';
import {
  FOLLOWING,
  FOLLOWING_TABLE_CONFIG,
  MOST_ISSUED,
  MOST_ISSUED_TABLE_CONFIG,
  MOST_RETIRED,
  MOST_RETIRED_TABLE_CONFIG,
  RECENTLY_ADDED,
  RECENTLY_ADDED_TABLE_CONFIG,
} from '../constants';

interface RatingTypes {
  label: string;
  key: string;
  description: string;
  event: string;
}

const ratingTypesConfig: Array<RatingTypes> = [
  {
    label: 'Recently Added',
    key: RECENTLY_ADDED,
    description: 'Ratings added in the last 30 days',
    event: EVNTAPP_tab_recently_added,
  },
  {
    label: 'Most Retired',
    key: MOST_RETIRED,
    description: 'Most retired projects in the last 30 days',
    event: EVNTAPP_tab_most_retired,
  },
  {
    label: 'Most Issued',
    key: MOST_ISSUED,
    description: 'Most issued projects in the last 30 days',
    event: EVNTAPP_tab_most_issued,
  },
  {
    label: 'Following',
    key: FOLLOWING,
    description: 'Projects you are following',
    event: EVNTAPP_tab_following,
  },
];

const getTableConfig = (currentlySelectedView: RatingTypes): TableConfig => {
  switch (currentlySelectedView.key) {
    case RECENTLY_ADDED:
      return RECENTLY_ADDED_TABLE_CONFIG;
    case MOST_RETIRED:
      return MOST_RETIRED_TABLE_CONFIG;
    case MOST_ISSUED:
      return MOST_ISSUED_TABLE_CONFIG;
    case FOLLOWING:
      return FOLLOWING_TABLE_CONFIG;
    default:
      return RECENTLY_ADDED_TABLE_CONFIG;
  }
};

const ViewRatings = () => {
  const navigate = useNavigate();
  const [currentlySelectedView, setCurrentlySelectedView] = useState<RatingTypes>(
    ratingTypesConfig[0]
  );

  const { data, isLoading } = useQuery({
    queryKey: [`dashboard-ratings-${currentlySelectedView.key}`],
    queryFn: async () =>
      await makeAuthenticatedGetRequest(dashboardProjects, {
        params: {
          type: currentlySelectedView.key,
          ...(currentlySelectedView.key === RECENTLY_ADDED ||
          currentlySelectedView.key === FOLLOWING
            ? {}
            : { limit: 5 }),
        },
      }),
    select: (data) => data?.data?.projects,
  });

  const transformedData = useMemo(() => {
    if (![RECENTLY_ADDED, FOLLOWING].includes(currentlySelectedView.key))
      return data?.map((project: any) => {
        return {
          ...project,
          name: `${project?.ghg_standard?.code}${project?.project_id} ${project?.name}`,
          logo: project?.project_types?.[0]?.project_type?.logo,
          hasPermission: project?.hasPermission,
        };
      });
    return data
      ?.map((project: any) => {
        return project?.project_crediting_periods?.map((period: any) => {
          return {
            ...project,
            name: `${project?.ghg_standard?.code}${project?.project_id} ${project?.name} ${period?.title ? `- ${period?.title}` : ''}`,
            ghgRating: period?.ratings?.ghg_rating_detail,
            sdgRating: period?.ratings?.sdg_rating_detail,
            hasPermission: project?.hasPermission,
            logo: project?.project_types?.[0]?.project_type?.logo,
          };
        });
      })
      .flat();
  }, [data, currentlySelectedView]);

  const handleTableRowClick = (rowData: any) => {
    const ghgStandard = rowData?.ghg_standard?.code;
    const projectId = rowData?.project_id;
    const creditingPeriodId = rowData?.project_crediting_periods?.[0]?.crediting_period_id;
    navigate(
      `/executive_summary?standard=${ghgStandard}&id=${projectId}&cp_no=${creditingPeriodId}`
    );
  };

  const handleSelectView = (rating: RatingTypes) => {
    track(rating.event, EVENT_DASHBOARD_PAGE);
    setCurrentlySelectedView(rating);
  };

  return (
    <Card sx={{ minHeight: '380px' }}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'flex-end'}
        mb={1}
        gap={1}
      >
        <Stack gap={1}>
          <Typography
            level='title-lg'
            sx={(theme) => {
              return { color: theme.palette.text.primary };
            }}
          >
            View ratings
          </Typography>
          <Typography
            level='body-sm'
            fontSize={'md'}
            sx={(theme) => {
              return { color: theme.palette.text.secondary };
            }}
          >
            {currentlySelectedView.description}
          </Typography>
        </Stack>
        <ButtonGroup color='primary'>
          {ratingTypesConfig.map((rating) => {
            const { key, label } = rating;
            const isSelected = currentlySelectedView.key === key;
            return (
              <Button
                variant={isSelected ? 'solid' : 'outlined'}
                sx={{ width: 'max-content' }}
                onClick={() => handleSelectView(rating)}
              >
                {label}
              </Button>
            );
          })}
        </ButtonGroup>
      </Stack>
      <DataTable
        data={transformedData}
        tableConfig={getTableConfig(currentlySelectedView)}
        loading={isLoading}
        onRowClick={handleTableRowClick}
      />
    </Card>
  );
};

export { ViewRatings };
