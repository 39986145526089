import { useEffect, useMemo, useState } from 'react';

import { Box, Card, CircularProgress, Typography, useColorScheme } from '@mui/joy';
import ReactEcharts from 'echarts-for-react';

import { groupObjectsByCommonKey } from '../../../../utils/functions/object';
import { ghgRatingsArray, sdgRatingsArray } from '../../constants';

interface PropTypes {
  rowData: any[];
  isLoading: boolean;
}

const DistributionDoughnut = ({ rowData, isLoading }: PropTypes) => {
  const { mode } = useColorScheme();
  const [showChart, setShowChart] = useState<boolean>(true);
  const data = useMemo(
    () =>
      Object.entries(groupObjectsByCommonKey(rowData, 'projectRegion'))
        .filter(([key]: any) => key !== 'undefined')
        .map(([key, value]: any) => {
          return {
            value: value?.length,
            name: key,
          };
        }),
    [rowData]
  );

  const option = useMemo(() => {
    return {
      title: {},
      backgroundColor: 'transparent',
      tooltip: {
        trigger: 'item',
        formatter: '{b}<br> <b>{c} ({d}%</b>)',
      },
      legend: {
        bottom: 0,
        textStyle: {
          fontFamily: "'Inter Variable', sans-serif",
          fontSize: 12,
          fontStyle: 'normal',
          fontWeight: 400,
        },
        icon: 'rect',
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          data,
          emphasis: {
            itemStyle: {
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowColor: 'rgba(255,255,255,255)',
            },
          },
          label: {
            show: false,
            position: 'inside',
            formatter: '{d}% ',
            fontSize: 12,
          },
          labelLine: {
            show: false,
            length: 20,
          },
        },
      ],
    };
  }, [data, ghgRatingsArray, sdgRatingsArray]);

  useEffect(() => {
    let timerId: NodeJS.Timeout | string | number | undefined = undefined;
    setShowChart(false);
    timerId = setTimeout(() => {
      setShowChart(true);
    }, 500);
    return () => clearTimeout(timerId);
  }, [option]);

  return (
    <Card>
      <Typography fontWeight={600} fontSize={'lg'}>
        GHG Rating Distribution by Region
      </Typography>
      {!isLoading && showChart ? (
        <Box>
          <ReactEcharts option={option} style={{ height: '400px' }} theme={mode} />
        </Box>
      ) : (
        <Box height={'400px'} width={'100%'} position={'relative'}>
          <CircularProgress
            color='primary'
            sx={{
              backgroundColor: 'transparent',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
            size='lg'
          />
        </Box>
      )}
    </Card>
  );
};

export { DistributionDoughnut };
