import React, { useEffect } from 'react';

import { Grid, Stack, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';

import { makeAuthenticatedGetRequest } from '../../services/axios';
import { donnasNotes } from '../../services/axios/endpoints';

import DonnasDescription from './components/DonnasNotes';
import DonnasNotesLoader from './components/skeletons/DonnasNotesLoader';
import { tansformDonnaNotes } from './constants';

export const DonnasNotes = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['donnaNotes'],
    queryFn: () => {
      const params = {};
      return makeAuthenticatedGetRequest(donnasNotes, { params });
    },
    select: (data) => tansformDonnaNotes(data?.data?.response),
  });

  useEffect(() => {
    if (data) {
      const contentBody = document.querySelectorAll('.donnas-notes-content .CToWUd');
      contentBody.forEach((body) => {
        body.setAttribute('referrerpolicy', 'no-referrer');
      });
    }
  }, [data]);

  return (
    <React.Fragment>
      {!isLoading ? (
        <Stack
          gap={3}
          alignContent={'flex-start'}
          sx={{ pb: 12, maxWidth: '90vw', minWidth: 'lg', mx: 'auto', pt: 3 }}
        >
          <Typography level='h2' fontSize={'xl3'}>
            Research Updates
          </Typography>
          <Grid
            xs={12}
            container
            gap={1}
            justifyContent={'flex-start'}
            direction={'column'}
            alignItems={'center'}
            sx={{ height: '100%' }}
          >
            <Grid xs={12} gap={3} direction={'row'} display={'flex'} alignItems={'center'}>
              <Typography level='body-md' color='primary'>
                A weekly update from our Research & Analytics team including newly rated and 'on
                watch' projects, our latest research findings, and perspectives on market
                developments.
              </Typography>
            </Grid>
          </Grid>
          <DonnasDescription data={data} isLoading={isLoading} />
        </Stack>
      ) : (
        <DonnasNotesLoader />
      )}
    </React.Fragment>
  );
};
