import React from 'react';

import { Button, Card, Stack, Typography, styled } from '@mui/joy';
import { IoMdAdd } from 'react-icons/io';

type AttributeProps = {
  id: string;
  display_name: string;
  description: string;
  handleClick: (id: string, display_name: string) => void;
};

const StyledTypography = styled(Typography)<{ clamp: number }>(({ clamp }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: `${clamp}`,
  WebkitBoxOrient: 'vertical',
}));

export const Attribute: React.FC<AttributeProps> = ({
  id,
  display_name,
  description,
  handleClick,
}) => {
  return (
    <Card sx={{ height: 1 }}>
      <Typography fontSize={'md'} fontWeight={'xl'} color='primary'>
        {display_name}
      </Typography>
      <StyledTypography
        level='body-sm'
        sx={{ color: (theme) => theme.palette.text.secondary }}
        clamp={2}
      >
        {description}
      </StyledTypography>
      <Stack direction={'row'} justifyContent={'flex-end'} mt={'auto'}>
        <Button
          size='sm'
          variant='outlined'
          endDecorator={<IoMdAdd />}
          onClick={() => handleClick(id, display_name)}
        >
          Learn more
        </Button>
      </Stack>
    </Card>
  );
};
