import { useState, useEffect, useRef } from 'react';

import { FilterObject } from '../../../components/filter/types';

import { PaginationState } from './types';

type usePaginationProps = {
  initialFilters: FilterObject;
  searchParams: URLSearchParams;
  setter: (paginateState: { limit?: number; page?: number }) => void;
  enabled: boolean;
};

const usePagination = ({
  initialFilters,
  enabled,
  searchParams,
  setter,
}: usePaginationProps): PaginationState => {
  const [limit, setLimit] = useState(
    Number(searchParams?.get('limit')) || Number(initialFilters?.limit) || 20
  );
  const [page, setPage] = useState(
    Number(searchParams?.get('page')) || Number(initialFilters?.page) || 1
  );
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const scrollParentRef = useRef(null);
  const [currentPageSet, setCurrentPageSet] = useState(0);
  const pagePerSet = 3;

  useEffect(() => {
    if (enabled) setter({ limit, page });
  }, [enabled, limit, page]);

  const updatePage = (newPage: number) => {
    setPage(newPage);
  };

  const updateLimit = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1); // Reset to first page when limit changes
  };

  const nextPage = () => {
    if (page < totalPages) {
      const newPage = page + 1;
      setPage(newPage);
      if (newPage > currentPageSet * pagePerSet + pagePerSet) {
        setCurrentPageSet(currentPageSet + 1);
      }
    }
  };

  const prevPage = () => {
    if (page > 1) {
      const newPage = page - 1;
      setPage(newPage);
      if (newPage <= currentPageSet * pagePerSet) {
        setCurrentPageSet(currentPageSet - 1);
      }
    }
  };

  const setScrollParentRef = (ref) => {
    scrollParentRef.current = ref;
  };

  const nextPageSet = () => {
    if ((currentPageSet + 1) * pagePerSet < totalPages) {
      setCurrentPageSet(currentPageSet + 1);
      const newPage = (currentPageSet + 1) * pagePerSet + 1;
      setPage(newPage);
    }
  };

  const prevPageSet = () => {
    if (currentPageSet > 0) {
      setCurrentPageSet(currentPageSet - 1);
      const newPage = currentPageSet * pagePerSet;
      setPage(newPage);
    }
  };

  const getPageSet = () => {
    const pages: number[] = [];
    const startPage = 1;
    const endPage = Math.min(startPage + totalPages - 1, totalPages);
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const itemsRange = `${(page - 1) * limit + 1} to ${Math.min(page * limit, totalCount)} of ${totalCount}`;
  const disableNextPageSet = (currentPageSet + 1) * pagePerSet >= totalPages;
  const disablePrevPageSet = currentPageSet === 0;
  const disableNextPage = page >= totalPages;
  const disablePrevPage = page <= 1;

  return {
    limit,
    page,
    totalCount,
    totalPages,
    itemsRange,
    scrollParentRef,
    pagePerSet,
    currentPageSet,
    disableNextPageSet,
    disablePrevPageSet,
    disableNextPage,
    disablePrevPage,
    getPageSet,
    nextPageSet,
    prevPageSet,
    updatePage,
    updateLimit,
    nextPage,
    prevPage,
    setTotalCount,
    setTotalPages,
    setPage,
    setScrollParentRef,
  };
};

export default usePagination;
