import { useAuthInfo, useRedirectFunctions } from '@propelauth/react';

const AuthContext = ({ children }) => {
  const { loading, isLoggedIn } = useAuthInfo();
  const { redirectToLoginPage } = useRedirectFunctions();

  if (loading) {
    return null;
  }

  if (!isLoggedIn) {
    redirectToLoginPage({
      userSignupQueryParameters: { opt_hint: 'pw,sso' },
      postLoginRedirectUrl: window.location.href,
    });
    return null;
  }

  return <>{children}</>;
};

export default AuthContext;
