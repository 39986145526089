export function areObjectsEqual(x: any, y: any) {
  return x && y && typeof x === 'object' && typeof y === 'object'
    ? Object.keys(x).length === Object.keys(y).length &&
        Object.keys(x).reduce(function (isEqual, key) {
          return isEqual && areObjectsEqual(x[key], y[key]);
        }, true)
    : x === y;
}

export const groupObjectsByCommonKey = (dataArray: any, groupByKey: string) => {
  const itemsGroupedByKey: any = {};
  dataArray?.forEach((item: any) => {
    if (itemsGroupedByKey?.hasOwnProperty(item?.[groupByKey])) {
      itemsGroupedByKey?.[item?.[groupByKey]]?.push(item);
    } else {
      Object.defineProperty(itemsGroupedByKey, item?.[groupByKey], {
        value: [item],
        writable: true,
        enumerable: true,
      });
    }
  });
  return itemsGroupedByKey;
};

export const isObjectUpdated = (original, current) => {
  if (original === current) {
    return false;
  }

  if (
    typeof original !== 'object' ||
    original === null ||
    typeof current !== 'object' ||
    current === null
  ) {
    return original !== current;
  }

  const originalKeys = Object.keys(original);
  const currentKeys = Object.keys(current);

  if (originalKeys.length !== currentKeys.length) {
    return true;
  }

  for (const key of originalKeys) {
    if (!currentKeys.includes(key) || isObjectUpdated(original[key], current[key])) {
      return true;
    }
  }

  return false;
};
