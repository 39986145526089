import { useMemo } from 'react';

import { Box, Card, Divider, IconButton, Stack, Typography } from '@mui/joy';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { track } from '../../../../services/analytics';
import {
  EVENT_EXECUTIVE_SUMMARY_PAGE,
  EVNTAPP_related_content_click,
  EVNTAPP_related_content_list_click,
} from '../../../../services/analytics/events';
import { projectState } from '../../../../stores/project';
import { SuggestedItem, SuggestedItemLoader } from '../../../home/components/LatestBlogs';

const emptyBlogs = Array(3).fill('');
const BLOGS_LIMIT = 2;

const RelatedContent = () => {
  const { search, pathname } = useLocation();
  const { blogs, isLoading, projectData } = useRecoilValue(projectState);
  const limitedBlogs = useMemo(
    () => (blogs?.length > BLOGS_LIMIT ? blogs.slice(0, BLOGS_LIMIT) : blogs),
    [blogs]
  );
  if ((blogs || []).length === 0 && !isLoading) return null;

  const handleShowMore = () => {
    track(EVNTAPP_related_content_list_click, EVENT_EXECUTIVE_SUMMARY_PAGE, {
      project_id: projectData?.projectId,
      page_url: `${pathname}${search}`,
    });
  };

  const handleBlogItem = () => {
    if (limitedBlogs.length > 0)
      track(EVNTAPP_related_content_click, EVENT_EXECUTIVE_SUMMARY_PAGE, {
        project_id: projectData?.projectId,
        page_url: `${pathname}${search}`,
      });
  };

  return (
    <Card sx={{ mb: 3, gap: 0, p: (theme) => theme.spacing(3) }}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography fontSize={20} fontWeight={'xl'} color='primary' mb={2}>
          Related Content
        </Typography>
        {blogs.length > BLOGS_LIMIT && (
          <Link to='/blog' onClick={handleShowMore}>
            <IconButton variant='outlined'>
              <ChevronRightIcon />
            </IconButton>
          </Link>
        )}
      </Stack>
      <Divider sx={{ width: 1, mx: 'auto', mt: (theme) => theme.spacing(2) }} />
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: (theme) => theme.spacing(1) }}
        onClick={handleBlogItem}
      >
        {isLoading
          ? emptyBlogs.map((item, index) => {
              return <SuggestedItemLoader key={index + item} />;
            })
          : (limitedBlogs || []).map((blog) => (
              <SuggestedItem
                key={blog.id}
                id={blog.id}
                title={blog.title}
                date={blog.date}
                imageURL={blog.visual}
              />
            ))}
      </Box>
    </Card>
  );
};

export default RelatedContent;
