import { updateImageBaseUrlInHTML } from '../../utils/functions/html';

export const tansformDonnaNotes = (data: any) => {
  return (data || []).map((notes) => {
    if (notes?.content) {
      return { ...notes, content: updateImageBaseUrlInHTML(notes?.content) };
    }
    return notes;
  });
};
