import { ReactNode, useState } from 'react';

import { Tooltip, Typography } from '@mui/joy';
import { Virtualizer } from '@tanstack/react-virtual';
import { IoSearchSharp } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import MessagePage from '../../components/MessagePage';
import Table from '../../components/table';
import { Cell } from '../../components/table/types';
import { retireeInsightState } from '../../stores/misc';
import { formatNumberWithCommas } from '../../utils/functions/number';
import { PaginationState } from '../../utils/hooks/usePagination/types';

type RetireeInsightsTableProps = {
  data: Array<any>;
  isLoading: boolean;
  paginationState: PaginationState;
  columnDetails: { columns: Cell[][]; order: string[] };
  footerCells: Cell[];
  rowVirtualizer: Virtualizer<Element, Element>;
  error: ReactNode | null;
  isFilterApplied: boolean;
  loaderRef: React.MutableRefObject<HTMLDivElement | null>;
  toggleSort: (key: string) => void;
  getSortValue: (key: string) => any;
};

export const RetireeInsightsTable: React.FC<RetireeInsightsTableProps> = ({
  data,
  isLoading,
  paginationState,
  toggleSort,
  getSortValue,
  columnDetails,
  footerCells,
  rowVirtualizer,
  error,
  loaderRef,
  isFilterApplied,
}) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { order, columns } = columnDetails;
  const [isDisabledClicked, setIsDiabledClicked] = useState(false);
  const setRetireeInsightsState = useSetRecoilState(retireeInsightState);

  const onRowClick = (row) => {
    const isDisabledRow = isRowDisabled(row);
    if (isDisabledRow) {
      setIsDiabledClicked(isDisabledRow);
    } else {
      setRetireeInsightsState({ retireeInsightsRedirectUrl: `${pathname}${search}` });
      navigate(`/retirement_insights/${row.retiree}`);
    }
  };

  const isRowDisabled = (row) => {
    const retiree = row?.retiree;
    if (retiree) {
      return !!(retiree?.toLowerCase() === 'total unknown companies');
    }
    return false;
  };

  return (
    <Table
      bodyStyle={{ height: 'calc(100vh - 320px)' }}
      hasInfiniteData
      error={error}
      columns={columns}
      cellRenderOrder={order}
      data={data}
      isLoading={isLoading}
      onRowClick={onRowClick}
      paginationState={paginationState}
      toggleSort={toggleSort}
      getSortValue={getSortValue}
      footerCells={footerCells}
      rowVirtualizer={rowVirtualizer}
      loaderRef={loaderRef}
      isRowDiabled={isRowDisabled}
      isStickyFooter
      isStickyHeader
      noDataMessage={
        <MessagePage
          title='No Results Found'
          description={
            isFilterApplied
              ? 'Please adjust your filter selection.'
              : `Retirement insights are searchable by company name only. <br/>
              More granularity is available in the filter menu. <br/>
              Retirements by project can be found using the Search page.`
          }
          hasHeading={false}
          iconProps={{
            size: 56,
          }}
          headingProps={{
            level: 'h4',
          }}
          descriptionProps={{
            level: 'title-md',
            width: '440px',
            textAlign: isFilterApplied ? 'center' : 'start',
          }}
          Icon={IoSearchSharp}
          action={
            isFilterApplied
              ? undefined
              : () => {
                  navigate('/search');
                }
          }
          actionText={isFilterApplied ? '' : 'Go to Search'}
        />
      }
      customCell={(data, key) => {
        if (key === 'retiree' && isRowDisabled(data)) {
          return (
            <Tooltip
              title='There are no details to show for Total Unknown Retirees'
              variant='solid'
              placement='right'
              open={isDisabledClicked}
              onClose={() => setIsDiabledClicked(false)}
              onMouseLeave={() => setIsDiabledClicked(false)}
              arrow
            >
              <Typography component={'div'} level='body-xs' sx={{ textAlign: 'start' }}>
                {data[key]}
              </Typography>
            </Tooltip>
          );
        }
        if (key === 'retiree') {
          return (
            <Typography
              level='body-xs'
              sx={{ textAlign: 'start', color: (theme) => theme.palette.text.primary }}
            >
              {data[key]}
            </Typography>
          );
        }
        if (key.indexOf('year') > -1 || key === 'total_volume') {
          return (
            <Typography
              level='body-xs'
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {formatNumberWithCommas(Number(data[key]))}
            </Typography>
          );
        }
        return null;
      }}
    />
  );
};
