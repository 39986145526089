import { useEffect, useState } from 'react';

import { CircularProgress, Sheet } from '@mui/joy';
import { useAuthInfo } from '@propelauth/react';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { setAccessToken } from '../services/axios/index';
import { appState } from '../stores/app';
import { redirectionURLState, userState } from '../stores/auth';
import useIsCalyxTeamMember from '../utils/hooks/useIsCalyxTeamMember';

import { Layout } from './Layout';

interface PropTypes {
  hideLayout?: boolean;
}

const FullScreenSpinner = () => {
  return (
    <Sheet
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <CircularProgress size='lg' />
    </Sheet>
  );
};

const ProtectedRoute: React.FC<PropTypes> = ({ hideLayout = false }) => {
  const setRedirectionUrl = useSetRecoilState(redirectionURLState);
  const [userInfo, setUserInfo] = useRecoilState(userState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const authInfo = useAuthInfo();
  const appStateData = useRecoilValue(appState);
  const calyxTeam = useIsCalyxTeamMember();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        setIsLoading(true);
        setAccessToken(authInfo.accessToken, calyxTeam);
        setUserInfo({
          company: authInfo.orgHelper?.getOrgs()?.[0]?.orgName as string,
          country: authInfo?.user?.properties?.country as string,
          mfa: authInfo?.userClass?.hasMfaEnabled as boolean,
          name: authInfo?.user?.firstName as string,
          surname: authInfo?.user?.lastName as string,
          profile_image: authInfo?.user?.pictureUrl as string,
          user_id: authInfo?.user?.userId as string,
          email: authInfo?.user?.email as string,
          calyx_team: calyxTeam,
          project_access_type: authInfo.orgHelper?.getOrgs()?.[0]?.userAssignedRole as string,
          user_permissions: authInfo.orgHelper?.getOrgs()?.[0]?.userPermissions as string[],
          user_roles: authInfo.orgHelper?.getOrgs()?.[0]?.userAssignedAdditionalRoles as string[],
        });
      } catch (err) {
        setUserInfo(null);
        return;
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserInfo();
  }, [setIsLoading, setUserInfo, appStateData]);

  if (isLoading) return <FullScreenSpinner />;

  if (!!userInfo?.user_id)
    return hideLayout ? (
      <Outlet />
    ) : (
      <Layout>
        <Outlet />
      </Layout>
    );
  setRedirectionUrl(`${window.location.pathname}${window.location.search}`);
};

export { ProtectedRoute };
