import { areObjectsEqual } from './object';

export const areArraysEqual = (a1: any, a2: any) =>
  a1.length === a2.length && a1.every((o: any, idx: any) => areObjectsEqual(o, a2[idx]));

export const getArrayDepth = <T>(arr: T[], childrenKey: keyof T): number => {
  let maxDepth = 0;

  const findDepth = (array: T[], currentDepth: number): void => {
    array.forEach((item) => {
      maxDepth = Math.max(maxDepth, currentDepth);
      if (item[childrenKey] && Array.isArray(item[childrenKey])) {
        findDepth(item[childrenKey] as unknown as T[], currentDepth + 1);
      }
    });
  };

  findDepth(arr, 1);
  return maxDepth;
};

export const sortByKey = (array: any[], key: string) => {
  return array.sort(function (a, b) {
    const x = a[key];
    const y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export const arrayToEnglishString = (arr) => {
  if (arr.length === 0) return '';
  if (arr.length === 1) return arr[0];

  // Join all elements except the last one with commas
  const allExceptLast = arr.slice(0, -1).join(', ');

  // Combine with 'and' for the last element
  return `${allExceptLast}, and ${arr[arr.length - 1]}`;
};

export const sortStringsAscending = (arr) => {
  if (!Array.isArray(arr)) {
    throw new Error('Input must be an array');
  }

  return arr.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });
};
