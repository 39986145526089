import { styled } from '@mui/joy';

const Wrapper = styled('div')(({ theme }) => ({
  a: {
    color: theme.palette['hyperlink']['text'],
  },
}));

export const GlobalCustomCSSProvider = ({ children }: { children: React.ReactNode }) => {
  return <Wrapper>{children}</Wrapper>;
};
