import { ImgHTMLAttributes, forwardRef } from 'react';

import fallback from '../assets/images/fallback.png';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt?: string;
  fallbackImage?: string;
  backgroundColor?: string;
}

const CommonImage = forwardRef<HTMLImageElement, ImageProps>(
  ({ src, alt, fallbackImage, ...rest }, ref) => {
    const onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const target = e.target as HTMLImageElement;
      target.src = fallbackImage || fallback; // Set src to empty string to prevent broken image display
    };
    return <img ref={ref} src={src} alt={alt} {...rest} onError={onError} />;
  }
);

export default CommonImage;
