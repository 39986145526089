import axios from 'axios';
import { getRecoil } from 'recoil-nexus';

import { appState } from '../../stores/app';
import queryClient from '../react-query';

export const axiosInstance = axios.create({ baseURL: process.env.BASE_URL });
export const authenticatedAxiosInstance = axios.create({ baseURL: process.env.BASE_URL });

export const setAccessToken = (accessToken, isCalyxTeam) => {
  authenticatedAxiosInstance.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
  const appStateValues = getRecoil(appState);
  if (isCalyxTeam && appStateValues.isStaging) {
    authenticatedAxiosInstance.defaults.headers['projectWorkflowState'] = 'staging';
  } else {
    delete authenticatedAxiosInstance.defaults.headers['projectWorkflowState'];
  }
  queryClient.removeQueries();
};

export const makeGetRequest = axiosInstance.get;
export const makePostRequest = axiosInstance.post;
export const makePutRequest = axiosInstance.put;
export const makeDeleteRequest = axiosInstance.delete;

export const makeAuthenticatedGetRequest = authenticatedAxiosInstance.get;
export const makeAuthenticatedPostRequest = authenticatedAxiosInstance.post;
export const makeAuthenticatedPutRequest = authenticatedAxiosInstance.put;
export const makeAuthenticatedDeleteRequest = authenticatedAxiosInstance.delete;
