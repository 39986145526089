import { useEffect } from 'react';

import { Box } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { makeAuthenticatedGetRequest } from '../../services/axios';
import {
  esrData,
  projectDetails,
  projectGhgSdg,
  projectIssuaranceData,
  relatedContent,
} from '../../services/axios/endpoints';
import { projectState } from '../../stores/project';

import ProjectHeader from './components/ProjectHeader';
import { ProjectPaywallModal } from './components/ProjectPaywallModal';
import ProjectSidebar from './components/ProjectSidebar';
import { getIssuanceSizeCategory, getProjectDetail } from './constants';

export const Project = () => {
  const [params] = useSearchParams();
  const setProjectState = useSetRecoilState(projectState);
  const resetProjectState = useResetRecoilState(projectState);
  const ghg_standard_code = params.get('standard');
  const crediting_period_id = params.get('cp_no');
  const project_id = params.get('id');
  const { data, isFetched, isRefetching } = useQuery({
    queryKey: [`project-detail`, crediting_period_id],
    queryFn: async () =>
      makeAuthenticatedGetRequest(projectDetails, {
        params: { project_id, crediting_period_id, ghg_standard_code },
      }),
    select: (data) => data?.data?.projects,
  });

  const {
    data: content,
    isFetched: isFetchedBlogs,
    isRefetching: isBlogsLoading,
  } = useQuery({
    queryKey: [`project-related-content`, crediting_period_id],
    queryFn: async () =>
      makeAuthenticatedGetRequest(relatedContent, {
        params: { project_id, crediting_period_id, ghg_standard_code },
      }),
    select: (data) => data?.data?.data,
  });

  const {
    data: essRatingData,
    isFetched: isFetchedEssRating,
    isRefetching: isEssLoading,
  } = useQuery({
    queryKey: [`project-ess-rating`, crediting_period_id],
    queryFn: async () =>
      makeAuthenticatedGetRequest(esrData, {
        params: { project_id, ghg_standard_code, crediting_period_id },
      }),
    select: (data) => data?.data.data,
  });

  const {
    data: projectComparisonData,
    isLoading: isProjectCompLoading,
    isRefetching: isProjectCompRefetch,
  } = useQuery({
    queryKey: [`project-sdg-ghg-all_project`, crediting_period_id],
    queryFn: async () =>
      makeAuthenticatedGetRequest(projectGhgSdg, {
        params: {
          project_id,
          crediting_period_id,
          ghg_standard_code,
          filter: 'all_project',
        },
      }),
    select: (data) => data?.data.graph,
  });

  const {
    data: projectCreditingData,
    isFetched: isProjectCreditingDataFetched,
    isRefetching: isProjectCreditingLoading,
  } = useQuery({
    queryKey: [`project-crediting`],
    queryFn: async () =>
      makeAuthenticatedGetRequest(projectIssuaranceData, {
        params: { project_id, ghg_standard_code },
      }),
    select: (data) => data?.data.issuanceData,
  });

  useEffect(() => {
    if (isRefetching || isBlogsLoading || isEssLoading || isProjectCreditingLoading) {
      setProjectState((value) => ({
        ...value,
        isLoading: true,
      }));
    } else if (isFetched && isFetchedBlogs && isFetchedEssRating && isProjectCreditingDataFetched) {
      const transformedProjectData = getProjectDetail(data);
      setProjectState((value) => ({
        ...value,
        projectData: transformedProjectData,
        blogs: content?.blogs || [],
        resources: content?.resources || [],
        projectComparisonData: projectComparisonData,
        essRatingData,
        projectCreditingData: {
          ...projectCreditingData,
          issuanceSizeCategory: getIssuanceSizeCategory(projectCreditingData?.totalIssued),
        },
        isLoading: false,
      }));
    }
  }, [
    isFetched,
    data,
    content,
    essRatingData,
    setProjectState,
    isFetchedBlogs,
    isFetchedEssRating,
    isBlogsLoading,
    isEssLoading,
    isRefetching,
    isProjectCompLoading,
    isProjectCompRefetch,
    projectComparisonData,
    projectCreditingData,
    isProjectCreditingDataFetched,
    isProjectCreditingLoading,
  ]);

  useEffect(() => {
    return () => {
      resetProjectState();
    };
  }, [resetProjectState]);

  return (
    <Box sx={{ height: 1, position: 'relative', width: 1 }}>
      {data?.hasPermission ? null : (
        <Box
          position={'absolute'}
          top={0}
          left={0}
          width={'100vw'}
          height={'calc(100vh - 90px)'}
          sx={{ backdropFilter: 'blur(10px)' }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
          zIndex={9}
        >
          {isFetched ? <ProjectPaywallModal /> : null}
        </Box>
      )}
      <ProjectHeader />
      <ProjectSidebar />
      <Box
        sx={{
          pl: 12,
          pt: 5,
          pr: 5,
          pb: 20,
          height: 1,
          overflowY: 'scroll',
          position: 'fixed',
          mb: 4,
          width: '100%',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
