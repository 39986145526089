import { Card, Chip, Stack, Tooltip, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { IoInformationCircleOutline } from 'react-icons/io5';

// import usePagination from '../../../commons/utils/hooks/usePagination';
import { makeAuthenticatedGetRequest } from '../../../../services/axios';
import { projectRetireeInsights } from '../../../../services/axios/endpoints';
import { dateFormat } from '../../../../utils/functions/date';

import { RetireeInsightsTable } from './retireeInsightsTable/RetireeInsightsTable';

interface PropTypes {
  ghgStandardCode: string | null;
  projectId: string | null;
}

export const ProjectRetirees = ({ ghgStandardCode, projectId }: PropTypes) => {
  const { data, isLoading } = useQuery({
    queryKey: ['retiree-analytics', ghgStandardCode, projectId],
    queryFn: async () =>
      await makeAuthenticatedGetRequest(projectRetireeInsights, {
        params: {
          ghg_standard_code: ghgStandardCode,
          project_id: projectId,
        },
      }),
    select: (data) => data?.data?.data,
  });

  if ((data?.retireeAnalytics || []).length === 0) return null;

  return (
    <Card>
      <Stack spacing={3}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={3}>
          <Typography level='h4'>Top retirees by volume (tCO2e)</Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Typography fontSize={'sm'} fontWeight={'xl'}>
              Information Updated:{' '}
              {isLoading
                ? 'Loading...'
                : !!data?.latestUpdate
                  ? dateFormat(data?.latestUpdate).mmddyyyy
                  : 'N/A'}
            </Typography>
            <Tooltip
              title='Beta Access: This feature will be available to select subscription plans post-beta'
              variant='solid'
              placement='top'
              arrow
            >
              <Chip variant='outlined' endDecorator={<IoInformationCircleOutline size={18} />}>
                Beta
              </Chip>
            </Tooltip>
          </Stack>
        </Stack>
        <RetireeInsightsTable isLoading={isLoading} data={data?.retireeAnalytics ?? []} />
      </Stack>
    </Card>
  );
};
