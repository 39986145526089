import { RESOURCE_TYPE } from '../../utils/constants';

// AUTH ENDPOINTS
export const login = '/auth/login';
export const verifyMFA = '/auth/mfa-verify';
export const forgotPassword = '/auth/forgot-password';
export const resendMFA = '/auth/resend-mfa';
export const resetPassword = '/auth/reset-password';

//USER MGMT ENDPOINTS
export const getRefreshToken = '/user/refresh';
export const getUserInfo = '/user/me';
export const changePassword = '/user/change-password';
export const profileImage = '/user/upload-profile-image';

//DASHBOARD ENDPOINTS
export const dashboardProjects = '/project/dashboard';
export const blogs = '/blog';
export const dashboardGraph = '/project/dashboard-graph';

//ASSETS
export const assetBaseUrl = process.env.ASSET_BASE_URL;
export const getVendorLogoURL = (slug: string) => `${assetBaseUrl}/public/img/vendor_logos/${slug}`;

export const getImageUrl = (slug: string) => `${assetBaseUrl}/public/img/${slug}`;

export const getAssetURL = (slug: string, type?: string) => {
  if (type === RESOURCE_TYPE) {
    return `${assetBaseUrl}/assets/files/resources/${encodeURIComponent(decodeURIComponent(slug))}`;
  } else {
    return `${assetBaseUrl}/assets/images/blog/${encodeURIComponent(decodeURIComponent(slug))}`;
  }
};

export const getAssetFileUrl = (slug: string) => {
  return `${assetBaseUrl}/assets/files/resources/${slug}`;
};

export const getRatingUrl = (slug: string) => {
  return `${assetBaseUrl}/public/img/rating/${slug}`;
};

export const getSdgTargetUrl = (slug: string) => {
  return `${assetBaseUrl}/public/img/sdg/sdg_target_${slug}.svg`;
};

//SEARCH PAGE ENDPOINTS
export const searchPageProjects = '/project';
export const searchPageFilters = '/project/search-filter';

//PROJECT DETAIL
export const projectDetails = '/project/details';
export const projectGhgSdg = '/project/project-comparison';
export const projectIssuaranceData = '/project/issuance-data';
export const projectSdgData = '/project/sdg-rating-data';
export const relatedContent = '/project/related-content';
export const projectSize = '/project/size-graph';
export const esrData = '/project/esr-data';
export const projectFollow = '/project/follow';
export const projectUnfollow = '/project/unfollow';
export const projectCreditSummary = '/project/annual-credit-summary';
export const projectRetirementComparison = '/project/retirement-comparison';

//WHERE TO BUY PAGE ENDPOINTS
export const whereToBuy = '/wheretobuy/all';
export const registerInterestRequest = '/wheretobuy/contact-vendor';

// RESOURCE PAGE ENDPOINTS
export const blogTags = '/blog/active-tags';

//PROJECT PAGE ENDPOINTS
export const projectGHGRatingData = '/project/ghg-rating-data';
export const resourceTags = '/resource/active-tags';
export const resources = '/resource';

// DONNAS NOTES ENDPOINTS
export const donnasNotes = 'notes/donnas-notes';

//RETIREE INSIGHTS ENDPOINTS
export const allRetireeInsights = '/retireeinsights/all';
export const filterMetadata = '/retireeinsights/filters_metadata';
export const projectRetireeInsights = '/retireeinsights/top_retirees';
export const projectRetireeTable = '/retireeinsights/project_table';
export const retireeAggregateData = '/retireeinsights/aggregate_stats';
export const retireeMarketShareData = '/retireeinsights/market_share';
export const retireeProjectPortfolio = '/retireeinsights/portfolio';
export const retireeBreakdownPieChart = '/retireeinsights/breakdown_stats';
export const retireeBreakdownTimeseriesChart = '/retireeinsights/breakdown_stats_timeseries';

//PROJECT ENDPOINTS
export const projectTypes = '/project_types';
export const projectCategories = '/project_groups';

//CONTACT PAGE ENDPOINTS
export const contact = '/user/contact-us';
