export const hasBaseUrl = (src) => {
  // Regular expression to check if the src starts with a scheme (e.g., http://, https://, ftp://, etc.)
  const urlPattern = /^[a-zA-Z][a-zA-Z\d+\-.]*:\/\//;
  return urlPattern.test(src);
};

export const getProjectAttributes = (data: any[]) => {
  if (!data)
    return {
      attributeNames: [],
      attributeDetails: [],
    };
  const filteredValues = data.filter((item) => item.attribute_value === true);
  const attributeNames = filteredValues.map((item) => item?.attribute?.display_name || '');
  const attributeDetails = filteredValues.map((item) => ({
    ...item.attribute,
    attribute_extra_content: item.attribute_extra_content,
  }));

  return {
    attributeNames,
    attributeDetails,
  };
};
