import { Box, Card, Grid, Typography } from '@mui/joy';

import { HTMLRenderer } from '../../project/ghg_rating/components/HTMLRenderer';

interface SocialSafeguardsSectionProps {
  title?: string;
  text?: string | string[];
  textAfter?: string;
  areasTitle: string;
  areas: { id: string; name: string; icon: JSX.Element }[];
}

const SocialSafeguardsSection = ({
  title,
  text,
  textAfter,
  areas,
  areasTitle,
}: SocialSafeguardsSectionProps) => {
  return (
    <>
      {title && (
        <Typography level='h2' fontSize={'xl2'}>
          {title}
        </Typography>
      )}
      {text &&
        Array.isArray(text) &&
        text.map((content) => <Typography level='body-md'>{content}</Typography>)}
      <Typography level='h4' fontSize={'xl'}>
        {areasTitle}
      </Typography>
      <Box sx={{ mt: 2, mb: 2, display: 'flex', flexWrap: 'wrap', gap: 3 }}>
        {areas.map((item) => (
          <Card
            variant='outlined'
            sx={{
              width: 0.48,
              height: 'auto',
              display: 'flex',
              background: (theme) => theme.palette.primary.outlinedColor,
            }}
          >
            <Grid container spacing={2} sx={{ flexGrow: 1 }} key={item.id}>
              <Grid alignItems='center' display={'flex'}>
                {item.icon}
              </Grid>
              <Grid alignItems='center' display={'flex'} sx={{ pl: '1rem', width: '80%' }}>
                <Typography level='title-lg' fontWeight={'lg'}>
                  {item.name}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        ))}
      </Box>
      {textAfter && (
        <HTMLRenderer dangerouslySetInnerHTML={{ __html: textAfter }} colorType='secondary' />
      )}
    </>
  );
};

export default SocialSafeguardsSection;
