import React from 'react';

import { Stack, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { makeAuthenticatedGetRequest } from '../../services/axios';
import { resourceTags, resources } from '../../services/axios/endpoints';
import { RESOURCE_TYPE } from '../../utils/constants';

import ListItem from './components/ListItemResource';
import Tags from './components/Tags';

export const Resource = () => {
  const [params, setParams] = useSearchParams();
  const selectedTag = params.get('tag');

  const { data: resourcesData, isLoading: isResourcesLoading } = useQuery({
    queryKey: ['resources', { tag: selectedTag }],
    queryFn: () => {
      const params = selectedTag ? { limit: 5, tagId: selectedTag } : {};
      return makeAuthenticatedGetRequest(resources, { params });
    },
    select: (data) => data?.data?.response,
  });

  const { data, isLoading } = useQuery({
    queryKey: ['ResourceTags'],
    queryFn: () => makeAuthenticatedGetRequest(resourceTags, { params: {} }),
    select: (data) => data?.data?.response,
  });

  const handleChangeTag = (tag: string | null) => {
    if (!tag && selectedTag) params.delete('tag');
    else {
      params.set('tag', tag || '');
    }
    setParams(params);
  };

  return (
    <React.Fragment>
      <Stack gap={3} alignContent={'flex-start'} sx={{ mx: 'auto', maxWidth: 'lg', pt: 3, pb: 10 }}>
        <Typography level='h1' fontSize={'xl3'}>
          Resources
        </Typography>
        {!isLoading && (
          <Tags value={selectedTag} tags={data} changeTag={handleChangeTag} isResources />
        )}
        <ListItem
          data={resourcesData}
          isLoading={isResourcesLoading}
          page={'resource-detail'}
          type={RESOURCE_TYPE}
        />
      </Stack>
    </React.Fragment>
  );
};
