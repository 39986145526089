import { Box, Skeleton, Stack, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';

import { makeAuthenticatedGetRequest } from '../../services/axios';
import { whereToBuy } from '../../services/axios/endpoints';

import { WhereToBuyCard } from './components/WhereToBuyCard';

export const WhereToBuy = () => {
  const { data, isLoading } = useQuery({
    queryKey: [`where-to-buy-projects`],
    queryFn: async () => makeAuthenticatedGetRequest(whereToBuy),
    select: (data) => data?.data?.projects,
  });
  return (
    <Box paddingBottom={12} maxWidth={'90vw'} minWidth={'lg'} margin={'auto'} paddingTop={3}>
      <Typography level='h1' fontSize={'xl3'}>
        Access Higher Rated Carbon Credits
      </Typography>
      <Stack spacing={2} marginY={2}>
        <Typography color='neutral' fontWeight={'md'} fontSize={'md'}>
          Calyx Global’s mission is to increase the impact of carbon markets. We are experimenting
          with this “Where to Buy” feature to help connect subscribers to our platform to sellers of
          credits with Calyx Global GHG Ratings (from A+ to B).
        </Typography>
        <Typography color='neutral' fontWeight={'md'} fontSize={'md'}>
          Below is a list of sellers with available inventory of highly-rated credits, as well as
          sellers with access to highly-rated credits (but that may not be “holding” inventory).
          Calyx Global does not act as an intermediary or agent on behalf of any seller, and
          receives no compensation or commission from any seller for providing this information.
        </Typography>
        <Typography color='neutral' fontWeight={'md'} fontSize={'md'}>
          The information is provided "as is" without any representation or warranty whatsoever and
          does not constitute any form of advice or recommendation. Calyx Global accepts no
          liability whatsoever for any loss, expense, cost or liability, howsoever arising from the
          subscriber’s decision to purchase any listed credit.
        </Typography>
      </Stack>
      {isLoading ? (
        <Stack spacing={2} marginTop={3}>
          {Array(5)
            .fill('')
            .map(() => {
              return <Skeleton variant='rectangular' height={'2.5rem'} animation='wave' />;
            })}
        </Stack>
      ) : (
        data?.map((item: any, index: number) => {
          return (
            <WhereToBuyCard key={item?.project_id} isChild={false} data={item} index={index} />
          );
        })
      )}
    </Box>
  );
};
