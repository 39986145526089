import { useRecoilValue } from 'recoil';

import { userState } from '../../../stores/auth';

export const useFetchUserPermissions = () => {
  const currentUserInfo = useRecoilValue(userState);
  const hasPermissionForReadMore = currentUserInfo?.user_permissions?.includes(
    'platform:deep_dive_access'
  );
  return { hasPermissionForReadMore };
};
