import { useCallback, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { FilterObject } from '../../../components/filter/types';

const useFilterParams = (
  initialFilters: FilterObject,
  callback?: (updatedFilters: FilterObject) => void,
  shouldReplace = false
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const removeEmptyValues = useCallback((obj: Record<string, any>): Record<string, any> => {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, value]) => {
          if (Array.isArray(value)) {
            return value.length > 0;
          } else if (typeof value === 'object' && value !== null) {
            return Object.keys(value).length > 0;
          } else if (typeof value === 'string') {
            return value.trim() !== '';
          } else {
            return value !== undefined && value !== null;
          }
        })
        .map(([key, value]) => {
          if (typeof value === 'object' && value !== null) {
            return [key, JSON.stringify(value)];
          }
          return [key, value];
        })
    );
  }, []);

  useEffect(() => {
    setFiltersFromQuery();
  }, [searchParams]);

  const updateSearchParams = useCallback(
    (params) => {
      const urlParams = new URLSearchParams();
      for (const [key, value] of Object.entries(params)) {
        urlParams.set(key, String(value));
      }
      if (urlParams.toString() !== searchParams.toString()) {
        if (shouldReplace) {
          const url = `${window.location.pathname}?${urlParams.toString()}`;
          window.history.replaceState({}, '', url);
        } else {
          setSearchParams(urlParams);
        }
      }
    },
    [searchParams, setSearchParams]
  );

  const setFiltersFromQuery = () => {
    const params = Object.fromEntries(searchParams.entries());
    const updatedFilters = {
      ...Object.keys(params).reduce((acc, key) => {
        const value = params[key];
        if (value) {
          try {
            acc[key] = JSON.parse(value);
          } catch (_) {
            acc[key] = value;
          }
        }
        return acc;
      }, {}),
    };

    callback && callback(searchParams.size === 0 ? initialFilters : updatedFilters);
  };

  const updateQueryParams = (filters: FilterObject) => {
    const nonEmptyFilters = removeEmptyValues(filters);
    updateSearchParams(nonEmptyFilters);
  };

  return { setFiltersFromQuery, updateQueryParams, searchParams, setSearchParams };
};

export default useFilterParams;
