import { Chip, Stack, Typography } from '@mui/joy';

import ProgressSemiCircle from '../../../../components/ProgressSemiCircle';

const Rating = ({
  score,
  percentage,
  sections,
  tag,
}: {
  score: number;
  percentage: number;
  sections?: string[];
  tag?: string;
}) => {
  return (
    <Stack direction={'row'} gap={4}>
      <Stack alignItems={'center'}>
        <Typography level='title-lg' fontWeight={'xl'}>
          SDG Impact Score
        </Typography>
        <ProgressSemiCircle percentage={percentage} value={score} total={10} size={100} />
        {tag && <Chip variant='outlined'>{tag}</Chip>}
      </Stack>
      <Stack width={'60%'}>
        {(sections || []).map((sectionTitle) => (
          <Typography level='body-md' color='primary'>
            {sectionTitle}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};

export default Rating;
