import { useState } from 'react';

import { Box, Card, Typography } from '@mui/joy';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../stores/project';
import ProjectSpecificRisksSection from '../safegaurds/components/ProjectSpecificRisksSection';
import RequestEsrReport from '../safegaurds/components/RequestEsrReport';
import SocialSafeguardsSection from '../safegaurds/components/SocialSafeguardsSection';

import { riskAreaTranformer } from './constants';
import { HTMLRenderer } from './ghg_rating/components/HTMLRenderer';

export const EnvironmentalAndSocialRisks = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { projectData, essRatingData: data } = useRecoilValue(projectState);

  return (
    <Card sx={{ mx: 'auto', maxWidth: 'lg', gap: 0, p: 3 }}>
      <Typography level='h2' color='primary' sx={{ mb: 1 }}>
        {data?.title}
      </Typography>
      <HTMLRenderer
        colorType='secondary'
        dangerouslySetInnerHTML={{ __html: data?.content || '' }}
      />
      <Box mt={2}>
        <SocialSafeguardsSection
          textAfter={data?.content_after_icons || ''}
          areasTitle={`Environmental and social risks inherent in ${projectData?.projectType || ''} projects are commonly found in the following areas : `}
          areas={riskAreaTranformer(data?.area_covered_relation || [])}
        />
      </Box>
      <ProjectSpecificRisksSection setIsModalOpen={setIsModalOpen} isOnProjectPage />
      <RequestEsrReport setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
    </Card>
  );
};
