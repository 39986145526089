import { useEffect, useMemo, useRef, useState } from 'react';

import { Card, Grid, Modal, ModalClose, ModalDialog, Skeleton, Typography } from '@mui/joy';
import { useRecoilValue } from 'recoil';

import { track } from '../../../../../services/analytics';
import {
  EVENT_EXECUTIVE_SUMMARY_PAGE,
  EVNTAPP_attribute_detail_clicked,
} from '../../../../../services/analytics/events';
import { projectState } from '../../../../../stores/project';

import { Attribute } from './Attribute';
import { AttributeDetails } from './AttributeDetails';

type DataType = {
  id: string;
  attribute_extra_content: null | string;
  display_name: string;
  short_description: string;
  long_description: string;
};

export const ProjectAttributes = () => {
  const [selectedAttribute, setSelectedAttribute] = useState<string>('');
  const { projectData, isLoading } = useRecoilValue(projectState);
  const attributesMap = useRef<{ [key: string]: DataType } | null>(null);
  const hasData = useMemo(
    () => !isLoading && projectData?.projectAttributes && projectData?.projectAttributes.length > 0,
    [isLoading, projectData]
  );
  useEffect(() => {
    attributesMap.current = (projectData?.projectAttributes || []).reduce((acc, value) => {
      if (!acc[value.id]) acc[value.id] = value;
      return acc;
    }, {});
  }, [projectData]);

  const handleClick = (id: string, display_name: string) => {
    track(EVNTAPP_attribute_detail_clicked, EVENT_EXECUTIVE_SUMMARY_PAGE, {
      project_id: projectData?.projectId,
      project_name: projectData?.title,
      project_category: projectData?.projectCategory,
      project_type: projectData?.projectType,
      attribute_name: display_name,
    });
    const map = attributesMap.current;
    if (map && id in map) {
      setSelectedAttribute(id);
    }
  };

  const handleCloseModal = () => {
    setSelectedAttribute('');
  };

  if (isLoading)
    return (
      <Card sx={{ pb: 5, gap: 0, p: (theme) => theme.spacing(3) }}>
        <Skeleton sx={{ width: 0.4, height: '30px' }} />
        <Skeleton sx={{ height: '180px', position: 'relative', mt: 7 }} />
      </Card>
    );

  if (hasData)
    return (
      <>
        <Card sx={{ pb: 5, gap: 0, p: (theme) => theme.spacing(3) }}>
          <Typography fontSize={20} fontWeight={'xl'} color='primary'>
            Project Attributes
          </Typography>
          <Grid container columnSpacing={2} rowSpacing={2} sx={{ mt: 2 }}>
            {projectData.projectAttributes.map((item) => (
              <Grid xs={4} key={item.id}>
                <Attribute
                  id={item.id}
                  display_name={item.display_name}
                  description={item.short_description}
                  handleClick={handleClick}
                />
              </Grid>
            ))}
          </Grid>
        </Card>
        <Modal open={!!selectedAttribute} onClose={handleCloseModal}>
          <ModalDialog maxWidth={'500px'}>
            <ModalClose sx={{ top: '20px', right: '20px' }} />
            <AttributeDetails
              display_name={
                (attributesMap.current && attributesMap.current[selectedAttribute]?.display_name) ||
                ''
              }
              long_description={
                (attributesMap.current &&
                  attributesMap.current[selectedAttribute]?.long_description) ||
                ''
              }
              attribute_extra_content={
                (attributesMap.current &&
                  attributesMap.current[selectedAttribute]?.attribute_extra_content) ||
                ''
              }
            />
          </ModalDialog>
        </Modal>
      </>
    );
};
