import React from 'react';

import { Stack, Typography } from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { makeAuthenticatedGetRequest } from '../../services/axios';
import { blogTags, blogs } from '../../services/axios/endpoints';

import ListItem from './components/ListItem';
import Tags from './components/Tags';

export const Blog = () => {
  const [params, setParams] = useSearchParams();
  const selectedTag = params.get('tag');
  const { data: blogsData, isLoading: isBlogsLoading } = useQuery({
    queryKey: ['blogs', { tag: selectedTag }],
    queryFn: () => {
      const params = selectedTag ? { tagId: selectedTag } : {};
      return makeAuthenticatedGetRequest(blogs, { params });
    },
    select: (data) => data?.data?.response,
  });

  const { data, isLoading } = useQuery({
    queryKey: ['blogTags'],
    queryFn: () => makeAuthenticatedGetRequest(blogTags, { params: {} }),
    select: (data) => data?.data?.response,
  });

  const handleChangeTag = (tag: string | null) => {
    if (!tag) params.delete('tag');
    else {
      params.set('tag', tag || '');
    }
    setParams(params);
  };

  return (
    <React.Fragment>
      <Stack gap={3} alignContent={'flex-start'} sx={{ mx: 'auto', maxWidth: 'lg', pt: 3, pb: 10 }}>
        <Typography level='h1' fontSize={'xl3'}>
          Subscriber Blogs
        </Typography>
        {!isLoading && <Tags value={selectedTag} tags={data} changeTag={handleChangeTag} />}
        <ListItem
          data={blogsData}
          isLoading={isBlogsLoading}
          page={'blog-detail'}
          selectedTag={selectedTag}
        />
      </Stack>
    </React.Fragment>
  );
};
