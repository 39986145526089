export const sum = (a, b) => {
  return a + b;
};

export const median = (values) => {
  values.sort(function (a, b) {
    return a - b;
  });

  const half = Math.floor(values.length / 2);

  if (values.length % 2 === 0) {
    return (values[half - 1] + values[half]) / 2;
  } else {
    return values[half];
  }
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numFormatter = (num) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);
  return item ? (num / item.value).toFixed(2).replace(regexp, '').concat(item.symbol) : '0';
};

export const formatBigNumber = (value) => {
  if (isNaN(value)) {
    return '-';
  }
  const number = Number(value);
  if (number < 1000) {
    return number.toLocaleString();
  } else if (number >= 1000 && number < 1000000) {
    return (number / 1000).toFixed(1) + 'K';
  } else if (number >= 1000000 && number < 1000000000) {
    return (number / 1000000).toFixed(1) + 'M';
  } else {
    return (number / 1000000000).toFixed(1) + 'B';
  }
};

export const formatNumberWithCommas = (number: number) => {
  if (isNaN(number)) {
    return '-';
  }
  return number.toLocaleString();
};
